import moment from 'moment';
import 'moment/locale/vi'

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank')
  if (newWindow) newWindow.opener = null
}

export const open = (url) => {
  window.open(url, '_self')
}

export const scrollToTop = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
};

export const scrollToTopWithoutAnimation = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'auto' });
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isEmpty = (text) => text?.trim() === '' || text === '' || text === undefined;

export const timeAgo = (timestamp) => timestamp ? moment(timestamp).locale("vi").fromNow() : '';

export const getGoogleDriveDisplayURL = (shareLink) => {
  const id = shareLink?.match(/\/d\/.*\/view/)?.pop()?.replace('/d/', '')?.replace('/view', '');
  return 'https://lh3.googleusercontent.com/d/' + id;
}

export function removeAccents(str) {
  try {
    var AccentsMap = [
      "aàảãáạăằẳẵắặâầẩẫấậ",
      "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
      "dđ", "DĐ",
      "eèẻẽéẹêềểễếệ",
      "EÈẺẼÉẸÊỀỂỄẾỆ",
      "iìỉĩíị",
      "IÌỈĨÍỊ",
      "oòỏõóọôồổỗốộơờởỡớợ",
      "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
      "uùủũúụưừửữứự",
      "UÙỦŨÚỤƯỪỬỮỨỰ",
      "yỳỷỹýỵ",
      "YỲỶỸÝỴ"    
    ];
    for (var i=0; i<AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
  } catch(e) {
    return str
  }
  return str;
}