import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import ScrollAnimation from "src/components/ScrollAnimation";
import useMobileOrTablet from "src/hooks/useMediaQuery";
import CarouselComponent from "src/pages/main/V2/HomePage/components/Client/components/Carousel";
import styled from "styled-components";
import {
  faAngleLeft,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";

const ButtonComp = styled.div`
  transition: all 0.4s;
  &:hover,
  &:active {
    transform: scale(1.1);
  }
`;

const FontAwesomeIconAnimated = styled(FontAwesomeIcon)`
  transition: all 0.4s;
  &:hover,
  &:active {
    transform: scale(1.1);
  }
`;

const Button = ({ icon, onClick }) => {
  return (
    <ButtonComp
      style={{
        cursor: "pointer",
        border: "solid white",
        borderWidth: 1,
        borderColor: "black",
        opacity: 0.5,
        width: 26,
        height: 26,
        borderRadius: 13,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "black",
      }}
      onClick={onClick}
    >
      <FontAwesomeIconAnimated
        icon={icon}
        color="white"
        size={'xs'}
      />
    </ButtonComp>
  );
};
const Client = (props) => {
  const isMobileOrTablet = useMobileOrTablet();
  const [font1, setFont1] = useState(24);
  const [font2, setFont2] = useState(14);
  const [font3, setFont3] = useState(12);
  const renderPopup = () => (
    <Popup
      trigger={
        <button className="border bg-black h-max my-auto text-sm text-white py-2 px-8 uppercase transition-colors duration-500 hover:bg-white hover:text-black md:text-left md:text-lg md:leading-7" style={{ position: 'absolute', bottom: 20, right: 20, fontSize: 14, fontSize: 14 }}>
          Chỉnh fontsize
        </button>}
      modal
    >
      <div>
        <label>
          Tiêu đề:
          <input className="border m-2" name="title" value={font1} onChange={e => setFont1(e.target.value)} />
        </label>
        <br />
        <label>
          tên:
          <input className="border m-2" name="desc" value={font2} onChange={e => setFont2(e.target.value)} />
        </label>
        <br />
        <label>
          Chia sẻ:
          <input className="border m-2" name="more" value={font3} onChange={e => setFont3(e.target.value)} />
        </label>
      </div>
    </Popup>
  )

  return (
    <div className="relative bg-heroBg flex flex-col md:flex-row my-[25%] md:mt-[15%] md:mb-0 py-[30px] gap-[20px]" style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      minHeight: isMobileOrTablet ? 'auto' : '100vh',
    }}>
      <h2 className="text-primary md:text-[40px] text-[30px] mx-[20px]">
        <ScrollAnimation animateIn="animation-fadeIn text-center">
          Khách hàng nói gì về Hidiarch?
        </ScrollAnimation>
      </h2>
      <div className="relative">
        <CarouselComponent font2={font2} font3={font3} />
      </div>
      {/* {renderPopup()} */}
    </div>
  );
};

export default Client;
