import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import Popup from "reactjs-popup";
import Button from "src/components/Button";
import ScrollAnimation from "src/components/ScrollAnimation";

const ContactUs = (props) => {
  const [font1, setFont1] = useState(24);
  const [font2, setFont2] = useState(12);
  const [font3, setFont3] = useState(14);
  const renderPopup = () => (
    <Popup
      trigger={
        <button className="border bg-black h-max my-auto text-sm text-white py-2 px-8 uppercase transition-colors duration-500 hover:bg-white hover:text-black md:text-left md:text-lg md:leading-7" style={{ position: 'absolute', bottom: 20, right: 20, fontSize: 14 }}>
          Chỉnh fontsize
        </button>}
      modal
    >
      <div>
        <label>
          Tiêu đề:
          <input className="border m-2" name="title" value={font1} onChange={e => setFont1(e.target.value)} />
        </label>
        <br />
        <label>
          Nội dung:
          <input className="border m-2" name="desc" value={font2} onChange={e => setFont2(e.target.value)} />
        </label>
        <br />
        <label>
          Nút xem thêm:
          <input className="border m-2" name="more" value={font3} onChange={e => setFont3(e.target.value)} />
        </label>
      </div>
    </Popup>
  )
  return (
    <div className="flex flex-col md:flex-row my-[25%] md:mt-[15%] md:mb-0">
      <div
        className="w-full md:w-1/2"
        style={{
          height: isMobile ? 414 : 750,
          background: isMobile ? `url(${require('./images/contact_us_pic_wide.jpg')})` : `url(${require('./images/cjontact_us_pic_wide_2.jpg')})`,
          backgroundAttachment: isMobile ? "unset" : "fixed",
          backgroundSize: "cover",
          backgroundPositionX: "50%",
          backgroundPositionY: "50%",
        }}
      ></div>
      <div className="w-full md:w-1/2 pt-[20px] md:pt-0 flex gap-[20px] flex-col justify-center padding-x-box md:pl-[6%] pr-0 md:pr-6">
        <h3 className="text-primary w-[80%] text-[24px md:text-[30px]">
          Bạn đã sẵn sàng cá nhân hoá không gian sống?
        </h3>
        <ScrollAnimation animateIn="animate-bounceInRight">
          <div className="h-[1px] w-[150px] md:w-[200px] bg-black" />
        </ScrollAnimation>
        <p className="text-primary w-full md:w-2/3 sub-text-size text-[14px]">
          Mong muốn và quyết định nằm ở bạn. Lắng nghe và hiện thực hoá là chúng
          tôi.
        </p>
        <Button fontSize={parseInt(font3)}>Liên hệ</Button>
      </div>
      {/* {renderPopup()} */}
    </div>
  );
};

export default ContactUs;
