import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  faFacebookF,
  faFacebookMessenger,
} from "@fortawesome/free-brands-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope as faContactUs } from "@fortawesome/free-regular-svg-icons";
import { openInNewTab, open } from "src/utils";
import styled from "styled-components";
import Logo from "../../../../assets/images/LOGO-DOC-1.jpg";
import useMobileOrTablet from "src/hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useWindowSize } from "react-use";

const ButtonComp = styled.div`
  transition: all 0.4s;
  &:hover,
  &:active {
    transform: scale(1.1);
  }
`;

const FontAwesomeIconAnimated = styled(FontAwesomeIcon)`
  transition: all 0.4s;
  &:hover,
  &:active {
    transform: scale(1.1);
  }
`;

const Button = ({ icon, onClick }) => {
  return (
    <ButtonComp
      style={{
        cursor: "pointer",
        border: "solid white",
        borderWidth: 1,
        borderColor: "black",
        width: 26,
        height: 26,
        borderRadius: 13,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "black",
      }}
      onClick={onClick}
    >
      <FontAwesomeIconAnimated icon={icon} color="white" size={"xs"} />
    </ButtonComp>
  );
};
let timer;
const DURATION = 3000;
const MOBILE_IFRAME_HEIGHT = 200;
const DESKTOP_IFRAME_HEIGHT = 160;

const displayTypes = [
  { type: "map", component: Map },
  { type: "facebook", component: Facebook },
];

function Map() {
  return (
    <iframe
      title="location"
      className="mx-auto w-full h-[130px]"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.963339477662!2d106.70487747583803!3d10.737308959912395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f9bb433f883%3A0x5c833e6ed77ffc61!2zNDEgxJDGsOG7nW5nIHPhu5EgNTIsIFAuIFacebookow7ogVGh14bqtbiwgUXXhuq1uIDcsIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1726078458552!5m2!1svi!2s"
      allowFullScreen=""
      loading="lazy"
    />
  );
}

// Facebook Component
function Facebook() {
  const { width } = useWindowSize();
  const iframeWidth = width < 768 ? width - width * 0.1 : 300;
  const linkIframe = `https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/HidiarchStudio&width=${iframeWidth}&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`;

  return (
    <iframe
      id="facebook-iframe"
      src={linkIframe}
      className="mx-auto w-full"
      allowTransparency="true"
      allow="encrypted-media"
    />
  );
}

const Footer = (props) => {
  const navigate = useNavigate();

  const [activeDisplayType, setActiveDisplayType] = useState("map");
  const [hoverDisplayType, setHoverDisplayType] = useState(null); // New state to track hover

  useEffect(() => {
    timer = setInterval(function () {
      setActiveDisplayType((prevType) =>
        prevType === "map" ? "facebook" : "map"
      );
    }, DURATION);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (hoverDisplayType) {
      setActiveDisplayType(hoverDisplayType);
      clearInterval(timer);
    } else {
      clearInterval(timer);
      timer = setInterval(function () {
        setActiveDisplayType((prevType) =>
          prevType === "map" ? "facebook" : "map"
        );
      }, DURATION);
    }
  }, [hoverDisplayType]);

  const renderDisplayComponent = () => {
    return displayTypes.map((displayType) => (
      <div
        key={displayType.type}
        className={clsx(
          "relative w-full",
          displayType.type === activeDisplayType && "animate-fadeInWidth"
        )}
      >
        <div
          className={clsx("absolute top-0 w-full md:w-[300px]", {
            "opacity-0": displayType.type !== activeDisplayType,
            "opacity-100": displayType.type === activeDisplayType,
          })}
        >
          <displayType.component />
        </div>
      </div>
    ));
  };

  const onClick = (type) => () => {
    switch (type) {
      case "phone":
        open("tel://0798897288");
        break;

      case "facebook":
        openInNewTab("https://www.facebook.com/HidiarchStudio");
        break;

      case "messenger":
        openInNewTab("https://m.me/HidiarchStudio");
        break;

      case "mail":
        open("mailto:hidiarch.studio@gmail.com");
        break;

      case "location":
        openInNewTab("https://maps.app.goo.gl/4cDemrWCk7uQBBy99");
        break;

      case "contactUs":
        navigate("/contact");
        break;

      default:
        break;
    }
  };

  return (
    <div className="border-t-primary border-t-2 flex flex-col-reverse gird lg:flex-row-reverse gap-12 lg:gap-0 p-[5%] justify-between">
      {
        <Wrapper>
          <div className="w-full md:w-[300px]">
            <div className="flex flex-row md:flex-row items-center justify-between">
              <h1 className="text-black mb-4 text-[16px] text-left font-bold">
                Liên Hệ Khác
              </h1>
              <div className="flex justify-center mb-4">
                <div
                  onMouseEnter={() => setHoverDisplayType("facebook")}
                  onMouseLeave={() => setHoverDisplayType(null)}
                >
                  <Button icon={faFacebookF} onClick={onClick("facebook")} />
                </div>
                <div
                  className="mx-2"
                  onMouseEnter={() => setHoverDisplayType("map")}
                  onMouseLeave={() => setHoverDisplayType(null)}
                >
                  <Button icon={faLocationDot} onClick={onClick("location")} />
                </div>
                <div>
                  <Button
                    icon={faFacebookMessenger}
                    onClick={onClick("messenger")}
                  />
                </div>
                <div className="ml-2">
                  <Button icon={faContactUs} onClick={onClick("contactUs")} />
                </div>
              </div>
            </div>
            <div className="h-[160px] w-full">{renderDisplayComponent()}</div>
          </div>

          <div className="">
            <h1 className="text-black mb-4 text-[16px] text-left font-bold">
              Giờ Hoạt Động
            </h1>
            <div className="flex flex-col">
              <p className="text-black text-[14px]">Thứ 2 - Thứ 6:</p>
              <p className="text-black text-[14px]">9H00 đến 18H00</p>
              <p className="text-black text-[14px]">Thứ 7:</p>
              <p className="text-black text-[14px]">9H00 đến 14H00</p>
            </div>
          </div>

          <div className="">
            <h1 className="text-black font-bold mb-4 text-left text-[16px] md:w-[120px]">
              Liên Kết Nhanh
            </h1>
            <div className="flex flex-col items-start">
              <button className="text-left text-[14px] hover:text-[#CE9460]">
                Trang chủ
              </button>
              <button className="text-left text-[14px] hover:text-[#CE9460]">
                Về chúng tôi
              </button>
              <button className="text-left text-[14px] hover:text-[#CE9460]">
                Dịch Vụ
              </button>
              <button className="text-left text-[14px] hover:text-[#CE9460]">
                Dự Án
              </button>
              <button className="text-left text-[14px] hover:text-[#CE9460]">
                Hỗ trợ
              </button>
            </div>
          </div>
        </Wrapper>
      }
      <div className="w-full">
        <div className="flex flex-row items-center gap-2 cursor-pointer md:justify-start mb-[10px] md:mb-[10px]">
          <img
            src={Logo}
            className="w-[30px] h-[30px] border rounded-[15px]"
            alt=""
          />
          <h1 className="text-black text-[16px] font-bold md:text-left">
            Hidiarch Studio
          </h1>
        </div>
        <div className="mb-[10px]">
          <p className="text-black text-[14px] md:w-[70%] md:text-left md:leading-7">
            Công Ty TNHH Kiến Trúc Nội Thất Hidiarch
          </p>
          <p className="text-black text-[14px] md:w-[100%] md:text-left md:leading-7">
            41 Đường số 52, KDC Tân Quy Đông, P. Tân Phong, Q7, TP HCM
          </p>
        </div>
        <div className="flex md:justify-start">
          <div className="text-[14px]">
            <button className="border bg-black flex h-max my-auto text-white py-1 px-4 uppercase transition-colors duration-500 hover:bg-white md:leading-7 hover:text-black">
              Hotline 0798 897 288
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Wrapper = ({ children }) => {
  const isMobile = useMobileOrTablet();

  if (isMobile) {
    return <>{children}</>;
  } else {
    return (
      <div className="flex w-full md:flex-row-reverse justify-between">
        {children}
      </div>
    );
  }
};

export default Footer;
