import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faFacebookF, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { faPhoneFlip, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import useMobileOrTablet from 'src/hooks/useMediaQuery';
import { openInNewTab, open } from 'src/utils';
import styled from 'styled-components'

const ButtonComp = styled.div`
    transition: all 0.4s;
    &:hover,
    &:active {
        transform: scale(1.1);
    }
`;

const FontAwesomeIconAnimated = styled(FontAwesomeIcon)`
  transition: all 0.4s;
    &:hover,
    &:active {
        transform: scale(1.1);
    }
`;

const Button = ({ icon, onClick }) => {
  const isMobileOrTablet = useMobileOrTablet()
  return (
    <ButtonComp
      style={{
        cursor: 'pointer',
        border: 'solid white',
        borderWidth: 1,
        borderColor: 'white',
        width: 40,
        height: 40,
        borderRadius: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: isMobileOrTablet ? 10 : 30
      }}
      onClick={onClick}
    >
      <FontAwesomeIconAnimated icon={icon} color='white' size={isMobileOrTablet ? 'sm' : '1x'} />
    </ButtonComp>
  )
}

export default function Footer() {
  const onClick = (type) => () => {
    switch (type) {
      case 'phone':
        open("tel://0798897288")
        break;

      case 'facebook':
        openInNewTab("https://www.facebook.com/HidiarchStudio")
        break;

      case 'messenger':
        openInNewTab("https://m.me/HidiarchStudio")
        break;
    
      case 'mail':
        open("mailto:hidiarch.studio@gmail.com")
        break;

      case 'location':
        openInNewTab("https://maps.app.goo.gl/4cDemrWCk7uQBBy99")
        break;
      
      default:
        break;
    }
  }

  return (
    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 100}}>
      <Button icon={faPhoneFlip} onClick={onClick('phone')} />
      <Button icon={faFacebookF} onClick={onClick('facebook')} />
      <Button icon={faFacebookMessenger} onClick={onClick('messenger')} />
      <Button icon={faEnvelope} onClick={onClick('mail')} />
      <Button icon={faLocationDot} onClick={onClick('location')} />
    </div>
  )
}