import React, { useRef } from "react";
import { getGoogleDriveDisplayURL } from "src/utils";
import styled from "styled-components";

/**
 * item's properties
 * name: string;
 * thumbnail: string[];
 * category: string;
 * id: string;
 */

export default function RelatedItem({ item, onClick, onMouseEnter, onMouseLeave }) {
  const timeout = useRef(null);
  const [isLongPress, setIsLongPress] = React.useState(false);

  // const handleMouseEnter = () => {
  //   onMouseEnter && onMouseEnter(item)
  // }

  const handleMouseLeave = () => {
    onMouseLeave && onMouseLeave();
  }

  const onTouchStart = () => {
    timeout.current = setTimeout(() => {
      onMouseEnter && onMouseEnter(item);
      setIsLongPress(true);
    }, 400);
  }

  const handleTouchEnd = () => {
    if (!isLongPress) {
      clearTimeout(timeout.current);
    }
    onMouseLeave && onMouseLeave();
    setIsLongPress(false);
  }
  
  return item?.name ? (
    <RelatedContenItem
      style={{ width: '30%', height: 'auto' }}
      onTouchStart={onTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={onTouchStart}
      onMouseLeave={handleMouseLeave}
      onClick={() => { onClick(item) }}
    >
      <img
        style={{ width: '100%', borderRadius: 8, objectFit: 'cover', pointerEvents: 'none' }}
        referrerPolicy="no-referrer" src={getGoogleDriveDisplayURL(item.mainPhotos?.[0]?.path ?? item.galleries?.[0]?.images?.[0].path)} alt=''
      />
      <h4 style={{ marginTop: 0 }}>{item?.name}</h4>
    </RelatedContenItem>
  ) : <div style={{ width: '30%', height: 'auto' }} />
}

const RelatedContenItem = styled.div`
  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Chrome */     
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
`;
