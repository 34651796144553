import "./App.css";
import React, { useEffect, useRef } from "react";
import router from "./router/RootRoute";
import { LoadingProvider } from "./hooks/useLoading";
import LoadingOverlay from "./components/LoadingOverlay";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./hooks/useAuth";
import { RouterProvider } from "react-router-dom";
//#endregion

const MAX_ZOOM_LEVEL = 2;

const MIN_ZOOM_LEVEL = -2;
function App() {
  const zoomLevel = useRef(0);
  // const getZoomLevel = () => {
  //   let zoomLevel = 'unknown';
  //   var screenCssPixelRatio = (window.outerWidth - 8) / window.innerWidth;
  //   if (screenCssPixelRatio >= .46 && screenCssPixelRatio <= .54) {
  //     zoomLevel = "-4";
  //   } else if (screenCssPixelRatio <= .64) {
  //     zoomLevel = "-3";
  //   } else if (screenCssPixelRatio <= .76) {
  //     zoomLevel = "-2";
  //   } else if (screenCssPixelRatio <= .92) {
  //     zoomLevel = "-1";
  //   } else if (screenCssPixelRatio <= 1.10) {
  //     zoomLevel = "0";
  //   } else if (screenCssPixelRatio <= 1.32) {
  //     zoomLevel = "1";
  //   } else if (screenCssPixelRatio <= 1.58) {
  //     zoomLevel = "2";
  //   } else if (screenCssPixelRatio <= 1.90) {
  //     zoomLevel = "3";
  //   } else if (screenCssPixelRatio <= 2.28) {
  //     zoomLevel = "4";
  //   } else if (screenCssPixelRatio <= 2.70) {
  //     zoomLevel = "5";
  //   } else {
  //     zoomLevel = "unknown";
  //   }
  //   return zoomLevel;
  // }

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.ctrlKey || e.metaKey) {
        console.log(zoomLevel.current);
        if (e.deltaY > 0 && zoomLevel.current < MAX_ZOOM_LEVEL) {
          zoomLevel.current = zoomLevel.current + 1;
          return;
        } else if (e.deltaY < 0 && zoomLevel.current > MIN_ZOOM_LEVEL) {
          zoomLevel.current = zoomLevel.current - 1;
          return;
        }
        e.preventDefault();
      }
    };

    const handleKeyDown = (e) => {
      console.log(zoomLevel.current);
      if ((e.ctrlKey || e.metaKey) && (e.key === '+' || e.key === '-' || e.key === '=')) {
        if ((e.key === '+' || e.key === '=') && zoomLevel.current < MAX_ZOOM_LEVEL) {
          zoomLevel.current = zoomLevel.current + 1;
          return;
        } else if (e.key === '-' && zoomLevel.current > MIN_ZOOM_LEVEL) {
          zoomLevel.current = zoomLevel.current - 1;
          return;
        }
        e.preventDefault();
      }
    };

    // Add event listeners when the component mounts
    window.addEventListener('wheel', handleWheel);
    window.addEventListener('keydown', handleKeyDown);

    // Clean up by removing event listeners when the component unmounts
    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  return (
    <LoadingProvider>
      <AuthProvider>
        <RouterProvider router={router} />
        <LoadingOverlay />
        <ToastContainer />
      </AuthProvider>
    </LoadingProvider>
  );
}

export default App;
