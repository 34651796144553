import React, { useEffect } from "react";
import "../../../App.css";
import styled from "styled-components";
import useStyles from "src/hooks/useStyles";
import CardInfo from "./shared/CardInfo";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export default function IntroductionPage() {
  const styles = useStyles();
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("@selectedIndexHamburder", 1);
    localStorage.setItem("@selectedIndexBottomBar", 1);
  }, [location]);

  return (
    <Container>
      <p style={{ ...styles.titleStyle, marginTop: 30, marginBottom: "1em" }}>
        Hidiarch - Lời Tự Sự
      </p>
      <p style={{ textAlign: "center", ...styles.descriptionStyle }}>
        - Công ty kiến trúc nội thất Hidiarch được thành lập vào năm 2021 bởi
        kiến trúc sư trẻ Phạm Huy Duy và người bạn đồng hành là nhà thiết kế
        Trần Trịnh Hoàng với nhiều kinh nghiệm trong nghề.
      </p>
      <p style={{ textAlign: "center", ...styles.descriptionStyle }}>
        - Hidiarch ra đời nhằm mang đến những giá trị thẩm mỹ, tối ưu công năng
        cho không gian sống cũng như lan tỏa niềm đam mê, nhiệt huyết của tuổi
        trẻ , góp phần phát triển và vươn xa hơn cho nền kiến trúc Việt Nam.
      </p>
      <p style={{ textAlign: "center", ...styles.descriptionStyle }}>
        - Do là những người thuộc thế hệ trẻ, nên các công trình Hidiarch mang
        đến sẽ luôn tươi mới, sáng tạo, tạo nên một hệ sinh thái kiến trúc nội
        thất vừa đẹp vừa thân thiện với thiên nhiên môi trường. Ngoài ra vẫn
        luôn gìn giữ và phát huy những nét đẹp truyền thống đi mãi theo thời
        gian.
      </p>

      <CardInfo
        name="PHẠM HUY DUY"
        role="Founder/ CEO/ Architect"
        slogan={`"Our passion is building your dream house."`}
        profileImageUrl={require("src/assets/images/1628755175704.jpeg")}
        profilePage="https://www.facebook.com/huyduy.pham.5/"
      />

      <CardInfo
        name="TRẦN TRỊNH HOÀNG"
        role="Co Founder/ Manager / Designer"
        slogan={`"Share all your desires, wishes, ideas and we will uniquely make them come true."`}
        profileImageUrl={require("src/assets/images/1632113682708.jpeg")}
        profilePage="https://zalo.me/0798897288"
      />

      <p
        style={{
          ...styles.titleStyle,
          marginTop: 50,
          marginBottom: "1em",
        }}
      >
        Hidiarch- Trách Nhiệm & Niềm Tin
      </p>
      <img
        style={{ width: "100%", height: "100%", borderRadius: 8 }}
        src={require("src/assets/images/7b5824d88f30786e2121.jpg")}
        alt=""
      />
      <p
        style={{ textAlign: "center", ...styles.subTitleStyle, marginTop: 50, marginBottom: "1rem" }}
      >
        Tâm huyết tạo nên trách nhiệm
      </p>
      <p style={{ textAlign: "center", ...styles.descriptionStyle }}>
        - Hidiarch luôn nổ lực khẳng định bản thân, đề cao tinh thần trách nhiệm
        với mỗi khách hàng và tạo dựng vị thế với từng đối tác.
      </p>
      <p style={{ textAlign: "center", ...styles.descriptionStyle }}>
        - Trải qua không ít khó khăn trong nghề, Hidiarch tự tin sẽ thấu hiểu
        những mong muốn, nhu cầu của khách hàng để luôn mang đến những thiết có
        giá trị và nâng tầm không gian sống.
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "row",
          width: "100%",
          marginTop: 20,
        }}
      >
        <div style={{ padding: 10, flex: 1 }}>
          <img
            style={{ width: "100%", height: "100%", borderRadius: 8 }}
            src={require("src/assets/images/1393650.jpg")}
            alt=""
          />
        </div>

        <div style={{ margin: 10, flex: 1 }}>
          <img
            style={{ width: "100%", height: "100%", borderRadius: 8 }}
            src={require("src/assets/images/20407836.jpg")}
            alt=""
          />
        </div>
      </div>

      <p
        style={{ textAlign: "center", ...styles.subTitleStyle, marginTop: 50, marginBottom: "1rem"  }}
      >
        Uy tín xây dựng niềm tin
      </p>
      <p style={{ textAlign: "center", ...styles.descriptionStyle }}>
        - Hidiarch đồng hành cùng bạn không chỉ trong toàn bộ quá thi công mà
        còn trong suốt thời gian sử dụng sau này với tiêu chí " Bảo Hành Nhiệt
        Tình- Bảo Trì Hợp Lý"
      </p>
      <p style={{ textAlign: "center", ...styles.descriptionStyle }}>
        - Niềm tin quý khách sẽ là động lực to lớn trong suốt chặn đường phát
        triển và thành công của Hidiarch
      </p>

      <img
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 8,
          marginTop: 30,
        }}
        src={require("src/assets/images/brandqhomepageheadercut-15681978166851185505060.jpg")}
        alt=""
      />
    </Container>
  );
}
