import React, { useEffect, useState } from "react";
import Image1 from "./images/img1.jpg";
import Image2 from "./images/img2.jpg";
import Image3 from "./images/img3.jpg";
import Image4 from "./images/img4.jpg";
import ScrollAnimation from "src/components/ScrollAnimation";
import Button from "src/components/Button";
import Popup from "reactjs-popup";

const OUTSTANDING_IMAGES = [Image1, Image2, Image3, Image4];

const Outstanding = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % OUTSTANDING_IMAGES.length
      );
    }, 3000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const [font1, setFont1] = useState(24);
  const [font2, setFont2] = useState(12);
  const [font3, setFont3] = useState(14);
  const renderPopup = () => (
    <Popup
      trigger={
        <button className="border bg-black h-max my-auto text-sm text-white py-2 px-8 uppercase transition-colors duration-500 hover:bg-white hover:text-black md:text-left md:text-lg md:leading-7" style={{ position: 'absolute', bottom: 20, right: 20, fontSize: 14 }}>
          Chỉnh fontsize
        </button>}
      modal
    >
      <div>
        <label>
          Tiêu đề:
          <input className="border m-2" name="title" value={font1} onChange={e => setFont1(e.target.value)} />
        </label>
        <br />
        <label>
          Nội dung:
          <input className="border m-2" name="desc" value={font2} onChange={e => setFont2(e.target.value)} />
        </label>
        <br />
        <label>
          Nút xem thêm:
          <input className="border m-2" name="more" value={font3} onChange={e => setFont3(e.target.value)} />
        </label>
      </div>
    </Popup>
  )

  return (
    <div className="h-auto md:h-screen mt-[50px] md:mt-auto flex flex-col md:flex-row container mx-auto relative ">
      <div className="padding-x-box grid gap-[20px] mb-[20px] md:w-3/4 h-auto mt-0 md:mt-6 md:h-auto md:z-10 md:absolute">
        <h3 className="text-primary title-2 md:mt-[70px] md:text-[50px] text-[30px]" style={{lineHeight: 1.5}}>
          <ScrollAnimation animateIn="animation-fadeIn">
            Tầm nhìn của bạn
            <br /> Giá trị từ chúng tôi
          </ScrollAnimation>
        </h3>
        <ScrollAnimation animateIn=" md:mt-0 animate-slowBounceInLeft">
          <div className="h-[1px] w-[200px] bg-black" />
        </ScrollAnimation>
        <p className="text-primary md:w-[50%] lg:w-2/3 sub-text-size text-[14px]">
          Chúng tôi mang đến cho bạn 3 giá trị thiết yếu: Giá trị thẩm mỹ, Giá
          trị công năng, Giá trị hoàn thiện thi công
          <br />
          Sau cùng cái chúng tôi hướng đến nhằm tao dựng nên một giá trị bền
          vững đó là giá trị về niểm tin
        </p>
        <Button>Xem thêm dự án</Button>
      </div>
      <div className="md:w-1/2 flex justify-end md:absolute right-0">
        <div className="relative">
        <img
          src={OUTSTANDING_IMAGES[currentIndex]}
          id="img"
          className="w-auto aspect-square md:h-[50vw] md:w-[50vw] object-cover"
          alt="..."
        />
        <div className="bg-white w-full h-full absolute top-0 bg-opacity-15"></div>
        </div>
      </div>
      {/* {renderPopup()} */}
    </div>
  );
};

export default Outstanding;
