import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import useScroll from "src/hooks/useScroll";
import styled from "styled-components";
import { HamburgerSqueeze } from 'react-animated-burgers';
import { useLayoutEffect } from "react";

const COLORS = {
  primaryDark: "#115b4c",
  primaryLight: "black",
};

const NavBackground = styled.div`
  /* position: absolute; */
  /* top: 6.5rem;
  right: 6.5rem; */
  /* background-image: radial-gradient(
    ${COLORS.primaryDark},
    ${COLORS.primaryLight}
  ); */
  background-color: rgba(40, 53, 51,1)
;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  z-index: 600;
  transform: ${(props) => (props.clicked ? "scale(80)" : "scale(0)")};
  transition: transform 0.8s;
`;

const Navigation = styled.nav`
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 600;
  width: ${(props) => (props.clicked ? "100%" : "0")};
  opacity: ${(props) => (props.clicked ? "1" : "0")};
  transition: width 0.8s, opacity 0.8s;
`;

const List = styled.ul`
  position: absolute;
  list-style: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* width: 100%; */
`;
const ItemLink = styled(NavLink)`
font-family: "Nunito";
  display: inline-block;
  font-size: 2rem;
  font-weight: 500;
  text-decoration: none;
  color: white;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 5px;
  /* background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  ); */
  background-size: 240%;
  transition: all 0.4s;

  &:hover,
  &:active {
    background-position: 100%;
    color: ${COLORS.primaryDark};
    transform: scale(1.1);
  }
`;

function HamburgerMenu() {
  const [blockScroll, allowScroll] = useScroll()
  const [index, setIndex] = useState('0');
  const [click, setClick] = useState(false);

  useLayoutEffect(() => {
    const index = localStorage.getItem('@selectedIndexHamburder');
    setIndex(index);
  })

  const handleClick = (i) => () => {
      i !== undefined && setIndex(i)
    setClick(!click)
    !click ? blockScroll() : allowScroll()
  };

  // const handleEscFunc = () => {
  //   click && handleClick()
  // }

  // useEffect(() => {
  //   document.addEventListener("keydown", handleEscFunc, false);

  //   return () => {
  //     document.removeEventListener("keydown", handleEscFunc, false);
  //   };
  // }, []);

  return (
    <>
      {/* <input style={{width: 0, height: 0, opacity: 0}} /> */}
      {/* <MenuLabel htmlFor="navi-toggle" onClick={handleClick}> */}
        {/* <Icon clicked={click}>&nbsp;</Icon> */}
        {/* <FontAwesomeIcon icon={click ? faXmark : faBars} size="3x"  onClick={handleClick} style={{zIndex: 1000, cursor: 'pointer'}} /> */}
        <HamburgerSqueeze
          className="hamburger-icon"
          buttonStyle={{
            outline: 0,
            transform: 'scale(0.8, 0.8)',
            color: 'white',
            zIndex: 1000,
            opacity: click ? 0 : 1
          }}
          barColor={'black'}
          isActive={click}
          toggleButton={handleClick()}
        />
      {/* </MenuLabel> */}
      

      <Navigation clicked={click}>
      <NavBackground onClick={handleClick()} clicked={click}>&nbsp;</NavBackground>
        {/* <div style={{position:'absolute', right: 20, top: 20}} onClick={handleClick}>
          <Icon clicked={click}>&nbsp;</Icon>
        </div> */}
        <List>
          <li>
            <ItemLink style={{color: index === '0' ? 'white' : '#ffffff60'}} onClick={handleClick(0)} to="/">
              Trang chủ
            </ItemLink>
          </li>
          <li>
            <ItemLink style={{color: index === '1' ? 'white' : '#ffffff60'}} onClick={handleClick(1)} to="/introduction">
              Giới thiệu
            </ItemLink>
          </li>
          <li>
            <ItemLink style={{color: index === '2' ? 'white' : '#ffffff60'}} onClick={handleClick(2)} to="/projects">
              Dự án
            </ItemLink>
          </li>
          <li>
            <ItemLink style={{color: index === '3' ? 'white' : '#ffffff60',}} onClick={handleClick(3)} to="/contact">
              Liên hệ
            </ItemLink>
          </li>
        </List>
      </Navigation>
    </>
  );
}

export default HamburgerMenu;
