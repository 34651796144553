import React, { createContext, useState, useContext } from 'react';

export const LoadingContext = createContext({});

export const LoadingProvider = ({ children }) => {
  const [loading, setloading] = useState(false);
  const showLoading = () => setloading(true)
  const hideLoading = () => setloading(false)
  
  return (
    <LoadingContext.Provider value={{ isLoading: loading, showLoading: showLoading, hideLoading: hideLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export function useLoading() {
  const context = useContext(LoadingContext);

  if (context === undefined) {
    throw new Error('useLoading must be used within an LoadingContext');
  }

  return context;
}
