import { useMemo } from "react";
import useMobileOrTablet from "./useMediaQuery";

export default function useStyles() {
  const isMobileOrTablet = useMobileOrTablet();

  const titleStyle = useMemo(() => ({
    fontWeight: 700,
    fontSize: isMobileOrTablet ? 22 : 26
  }), [isMobileOrTablet])

  const titleStyleV2 = useMemo(() => ({
    fontWeight: 500,
    fontSize: isMobileOrTablet ? 18 : 30
  }), [isMobileOrTablet])

  const adminTitleStyle = useMemo(() => ({
    fontWeight: 700,
    fontSize: isMobileOrTablet ? 20 : 24
  }), [isMobileOrTablet])

  const subTitleStyle = useMemo(() => ({
    fontWeight: 500,
    fontSize: isMobileOrTablet ? 18 : 22
  }), [isMobileOrTablet])

  const adminSubTitleStyle = useMemo(() => ({
    fontWeight: 500,
    fontSize: isMobileOrTablet ? 16 : 20
  }), [isMobileOrTablet])

  const descriptionStyle = useMemo(() => ({
    marginTop: "1em",
    marginBottom: "1em",
    fontSize: isMobileOrTablet ? 16 : 18
  }), [isMobileOrTablet])

  const descriptionStyleV2 = useMemo(() => ({
    marginTop: "1em",
    marginBottom: "1em",
    fontSize: isMobileOrTablet ? 12 : 16
  }), [isMobileOrTablet])

  const adminDescriptionStyle = useMemo(() => ({
    fontSize: isMobileOrTablet ? 14 : 16
  }), [isMobileOrTablet])

  const description = useMemo(() => ({
    fontSize: isMobileOrTablet ? 12 : 16
  }), [isMobileOrTablet])

  const descriptionStyles = {
    description
  }

  return {
    titleStyle,
    subTitleStyle,
    descriptionStyle,
    adminDescriptionStyle,
    adminSubTitleStyle,
    adminTitleStyle,
    descriptionStyleV2,
    titleStyleV2,
    ...descriptionStyles
  }
}