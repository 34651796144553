import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/main/homepage/index.js";
import Layout from "./Layout";
import IntroductionPage from "src/pages/main/Introduction";
import ProjectsPage from "src/pages/main/Projects";
import ProjectsCategoryPage from "src/pages/main/Projects/ProjectCategory";
import ProjectDetailPage from "src/pages/main/Projects/ProjectDetail";
import ContactPage from "src/pages/main/Contact";
import LoginPage from "src/pages/admin/Login";
import LoginLayout from "./LoginLayout";
import NotFoundPage from "src/pages/NotFound";
import AdminLayout from "./AdminLayout";
import MailPage from "src/pages/admin/Mail";
import GalleryPage from "src/pages/admin/Gallery";
import ManageProjectsPage from "src/pages/admin/ManageProjects";
import ManageProjectDetailPage from "src/pages/admin/ManageProjects/ManageProjectDetail";
import GreyLayout from "./GreyLayout.js";
import LayoutV2 from "./LayoutV2/LayoutV2.js";
import HomePageV2 from "src/pages/main/V2/HomePage/HomePage.js";

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        path: "",
        element: (
          <Layout>
            <HomePage />
          </Layout>
        ),
      },
      {
        path: "introduction",
        element: (
          <Layout>
            <IntroductionPage />
          </Layout>
        ),
      },
      {
        path: "projects",
        element: (
          <Layout>
            <ProjectsPage />
          </Layout>
        ),
      },
      {
        path: "projects/civil-construction",
        element: (
          <Layout>
            <ProjectsCategoryPage />
          </Layout>
        ),
      },
      {
        path: "projects/civil-construction/townhouse",
        element: (
          <GreyLayout>
            <ProjectDetailPage />
          </GreyLayout>
        ),
      },
      {
        path: "projects/civil-construction/apartment",
        element: (
          <GreyLayout>
            <ProjectDetailPage />
          </GreyLayout>
        ),
      },
      {
        path: "projects/public-construction",
        element: (
          <GreyLayout>
            <ProjectDetailPage />
          </GreyLayout>
        ),
      },
      {
        path: "projects/actual",
        element: (
          <GreyLayout>
            <ProjectDetailPage />
          </GreyLayout>
        ),
      },
      {
        path: "contact",
        element: (
          <Layout>
            <ContactPage />
          </Layout>
        ),
      },
      {
        path: "admin",
        element: (
          <LoginLayout>
            <LoginPage />
          </LoginLayout>
        ),
      },
      {
        path: "not-found",
        element: (
          <LoginLayout>
            <NotFoundPage />
          </LoginLayout>
        ),
      },
      {
        path: "*",
        element: <Navigate to="/not-found" replace />,
      },
      {
        path: "admin/mail",
        element: (
          <AdminLayout>
            <MailPage />
          </AdminLayout>
        ),
      },
      {
        path: "admin/gallery",
        element: (
          <AdminLayout>
            <GalleryPage />
          </AdminLayout>
        ),
      },
      {
        path: "admin/projects",
        element: (
          <AdminLayout>
            <ManageProjectsPage />
          </AdminLayout>
        ),
      },
      {
        path: "admin/projects-detail",
        element: (
          <AdminLayout>
            <ManageProjectDetailPage />
          </AdminLayout>
        ),
      },
    ],
  },
  {
    path: "/v2",
    children: [
      {
        path: "",
        element: (
          <LayoutV2>
            <HomePageV2 />
          </LayoutV2>
        ),
      },
    ],
  },
]);

export default router;
