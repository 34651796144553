import React, { useLayoutEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMobileOrTablet from 'src/hooks/useMediaQuery';
import styled from 'styled-components';
import { HamburgerSpin } from 'react-animated-burgers';
import { faBook, faEnvelopesBulk, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import useStyles from 'src/hooks/useStyles';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import { toast } from 'react-toastify';

const expandedWidth = 250;
const collapsedWidth = 75;

const menu = [
  {
    id: 'mail',
    path: '/admin/mail',
    icon: faEnvelopesBulk,
    title: 'Hòm thư'
  },
  {
    id: 'projects',
    path: '/admin/projects',
    icon: faBook,
    title: 'Dự án'
  },
  // {
  //   id: 'gallery',
  //   path: '/admin/gallery',
  //   icon: faImage,
  //   title: 'Kho ảnh'
  // },
]

const AnimatedDiv = styled.div`
  transition: all 0.4s;
  cursor: pointer;
  background-size: 240%;
  transition: all 0.4s;
  padding: 10px;

  &:hover,
  &:active {
    color: white;
    background-color: #115b4c;
  }
`;

export default function Sidebar() {
  const styles = useStyles();
  const navigate = useNavigate();
  const auth = getAuth();

  const isMobileOrTablet = useMobileOrTablet();
  const [isExpanded, setIsExpanded] = useState(isMobileOrTablet === false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useLayoutEffect(() => {
    const index = localStorage.getItem('@selectedIndex');
    setSelectedIndex(index);
  }, [])

  const handleClick = () => {
    setIsExpanded(prev => !prev);
  }

  const onClick = (path, index) => {
    localStorage.setItem('@selectedIndex', index);
    setSelectedIndex(index);
    navigate(path);
  }

  const onSignOut = () => {
    signOut(auth).then(() => {
      localStorage.setItem('@token', '')
      localStorage.setItem('@username', '')
      navigate('/admin');
    }).catch((error) => {
      toast.error('Đăng xuất không thành công');
    });
  }

  return (
    <>
      <div style={{ width: isExpanded ? expandedWidth : collapsedWidth, position: 'fixed', left: 0, top: 0, bottom: 0, transition: 'all ease 0.4s', backgroundColor: 'white', boxShadow: '0px 2px 8px 2px #00000010' }}>
        <div style={{
          alignItems: 'center',
          backgroundImage: `url(${'https://bio.linkcdn.to/20210809/1628521194649.jpeg'})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -100,
        }} />
        <div>
          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', marginTop: 10 }}>
            {isExpanded && <img src='https://bio.linkcdn.to/20210809/1628520221869.jpeg' style={{ width: 50, height: 50, borderRadius: 25, marginLeft: 10 }} alt='' />}
            <HamburgerSpin
              className="hamburger-icon"
              buttonStyle={{
                outline: 0,
                transform: 'scale(0.7, 0.7)',
                color: 'white',
                zIndex: 1000,
              }}
              barColor={'white'}
              isActive={isExpanded}
              toggleButton={handleClick}
            />
          </div>
          <div>
            <div style={{ height: 20 }} />
            {menu.map((option, index) => {
              return (
                <AnimatedDiv
                  onClick={() => onClick(option.path, index)}
                  key={option.id}
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: index === selectedIndex ? '#115b4c' : 'transparent' }} >
                  <FontAwesomeIcon icon={option.icon} color='white' size='sm' style={{ margin: 10, marginLeft: 14, width: 20 }} />
                  {isExpanded && <p style={{ color: 'white', padding: 0, margin: 0, ...styles.adminDescriptionStyle }}>{option.title}</p>}
                </AnimatedDiv>
              )
            })}

            <AnimatedDiv style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }} onClick={onSignOut}>
              <FontAwesomeIcon icon={faRightFromBracket} color='white' size='sm' style={{ margin: 10, marginLeft: 14 }} />
              {isExpanded && <p style={{ color: 'white', ...styles.adminDescriptionStyle }}>Đăng xuất</p>}
            </AnimatedDiv>

          </div>
        </div>
      </div>
      <div style={{ height: '100vh', zIndex: -10, width: isExpanded ? expandedWidth : collapsedWidth, transition: 'all ease 0.4s' }}></div></>

  )
}