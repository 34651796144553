import React, { useEffect, useState } from 'react';
import '../../../App.css';
import styled from 'styled-components';
import useStyles from 'src/hooks/useStyles';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { toast } from 'react-toastify';
import { validateEmail } from 'src/utils';
import { useNavigate } from 'react-router-dom';

const ItemLink = styled.p`
  align-self: flex-end;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  text-decoration: none;
  color: white;
  padding: 1rem 2rem;
  margin-top: 40px;

  border: solid  white;
  border-width: 1px;
  text-align: center;
  cursor: pointer;

  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;

  &:hover,
  &:active {
    background-position: 100%;
    color: #115b4c;
  }
`;

const Container = styled.div`
    align-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: -50px;
`;

const auth = getAuth();

export default function LoginPage() {
  const styles = useStyles()
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    toast.dismiss()
  }, [email, password])

  const onLogin = () => {
    if (email.trim() === '') {
      toast.error('Email không được để trống');
      return;
    }
    if (!validateEmail(email.trim())) {
      toast.error('Email không hợp lệ');
      return
    }
    if (password.trim() === '') {
      toast.error('Password không được để trống');
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        localStorage.setItem('@token', result.user.accessToken)
        localStorage.setItem('@username', email)
        navigate('/admin/mail');
      }).catch((error) => {
        toast.error('Tài khoản không tồn tại');
      });
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onLogin()
    }
  }

  return (
    <Container>
      <img src={require('src/assets/images/LOGO-NGANG-1.jpg')} style={{ width: 200, borderRadius: 10, marginBottom: 40 }} alt='' />
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>

        <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, textTransform: 'uppercase', marginBottom: 2 }}>Tên đăng nhập</p>
        <input
          type='email'
          style={{ ...styles.descriptionStyle, height: 55, margin: 0, paddingLeft: 10, borderWidth: 0 }}
          value={email}
          onChange={e => setEmail(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, textTransform: 'uppercase', marginBottom: 2 }}>Mật khẩu</p>
        <input
          style={{ ...styles.descriptionStyle, height: 55, margin: 0, paddingLeft: 10, borderWidth: 0 }}
          value={password}
          type='password'
          onChange={e => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <ItemLink onClick={onLogin}>ĐĂNG NHẬP</ItemLink>
      </div>
    </Container>
  )
}