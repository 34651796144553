import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import animationData from '../../assets/animation/loading.json'
import { useLoading } from 'src/hooks/useLoading';

const Container = styled.div`
  position: fixed;
  background-color: #00000040;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 2000;
`;

export default function LoadingOverlay() {
  const loading = useLoading();

  return loading.isLoading ? (
    <Container>
      <Lottie
        width={100}
        height={100}
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }} />
    </Container>
  ) : <></>
}