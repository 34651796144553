import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import Sidebar from 'src/components/Sidebar';
import useMobileOrTablet from 'src/hooks/useMediaQuery';
import styled from 'styled-components';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { scrollToTop } from 'src/utils';

const ToTopButton = styled(FontAwesomeIcon)`
    transition: all 0.4s;
    border-radius: 20px;
    &:hover,
    &:active {
        transform: scale(1.1);
    };
`;

export default function AdminLayout({ children }) {
    const isMobileOrTablet = useMobileOrTablet()
    const navigate = useNavigate();
    const [onTop, setOnTop] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('@token');
        if (token?.trim() === '' || token === null || token === undefined) {
            navigate('/admin');
        }
    });


    useEffect(() => {
        window.onscroll = () => {
            if (window.pageYOffset <= 50) {
                onTop === false && setOnTop(true)
            } else {
                onTop === true && setOnTop(false)
            }
        }

        return () => (window.onscroll = null);
    });

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flex: 'column',
            }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hidiarch Studio Admin</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link
                    href="https://bio.linkcdn.to/20210809/1628520221869.jpeg"
                    rel="icon"
                />
            </Helmet>

            <div style={{
                padding: 0,
                minWidth: 100,
                width: '100%',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'stretch',
                alignItems: 'stretch',
                position: 'relative',
                backgroundColor: '#e7e7e7',
            }}>
                <Sidebar />
                {children}
            </div>

            {!onTop && <ToTopButton
                icon={faAngleUp}
                onClick={scrollToTop}
                style={{
                    position: 'fixed',
                    bottom: isMobileOrTablet ? 20 : 20,
                    right: isMobileOrTablet ? 20 : 20,
                    backgroundColor: '#115b4c',
                    width: isMobileOrTablet ? 20 : 20,
                    height: isMobileOrTablet ? 20 : 20,
                    padding: 10,
                    borderRadius: 50,
                    overflow: 'hidden',
                    cursor: 'pointer',
                }} />
            }

        </div>
    )
}