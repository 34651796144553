import React from 'react';
import '../../App.css';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useMobileOrTablet from 'src/hooks/useMediaQuery';

const ItemLink = styled.p`
  align-self: center;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  color: white;
  padding: 1rem 2rem;
  margin-top: 100px;

  border: solid  white;
  border-width: 1px;
  text-align: center;
  cursor: pointer;

  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;

  &:hover,
  &:active {
    background-position: 100%;
    color: #115b4c;
  }
`;

const Container = styled.div`
    align-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: -50px;
`;

export default function NotFoundPage() {
  const navigate = useNavigate();
  const isMobileOrTablet = useMobileOrTablet()

  return (
    <Container>
      <p style={{fontSize: isMobileOrTablet?  100: 200, margin: 0}}>404</p>
      <p style={{margin: 0, fontSize: isMobileOrTablet ? 20 : 40}}>Không tìm thấy nội dung</p>
      <ItemLink onClick={() => navigate('/')} >QUAY VỀ TRANG CHỦ</ItemLink>
    </Container>
  )
}