import React, { useState } from "react";
import WelcomeImage from "./images/homepage_company.jpg";
import Button from "src/components/Button";
import ScrollAnimation from "src/components/ScrollAnimation";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Welcome = () => {
  const [font1, setFont1] = useState(30); // mobi 24
  const [font2, setFont2] = useState(14); // 14
  const [font3, setFont3] = useState(14); // 14
  const renderPopup = () => (
    <Popup
      trigger={
        <button className="border bg-black h-max my-auto text-sm text-white py-2 px-8 uppercase transition-colors duration-500 hover:bg-white hover:text-black md:text-left md:text-lg md:leading-7" style={{ position: 'absolute', bottom: 20, right: 20, fontSize: 14 }}>
          Chỉnh fontsize
        </button>}
      modal
    >
      <div>
        <label>
          Tiêu đề:
          <input className="border m-2" name="title" value={font1} onChange={e => setFont1(e.target.value)} />
        </label>
        <br />
        <label>
          Nội dung:
          <input className="border m-2" name="desc" value={font2} onChange={e => setFont2(e.target.value)} />
        </label>
        <br />
        <label>
          Nút xem thêm:
          <input className="border m-2" name="more" value={font3} onChange={e => setFont3(e.target.value)} />
        </label>
      </div>
    </Popup>
  )
  return (
    <div className="h-auto md:h-[100vh] flex flex-col md:flex-row items-center justify-center p-[20px] md:gap-[5vw] pb-[100px] md:pb-[0px]" style={{ position: 'relative' }}>
      <div className="w-[100vw] h-[100vw] md:h-[40vw] md:w-[40vw] p-[20px] pt-[0px] aspect-square	">
        <img src={WelcomeImage} className="object-cover w-full h-full" alt="" />
      </div>
      <div className="md:w-[40vw] flex flex-col gap-[10px] md:gap-[20px]">
        <ScrollAnimation animateIn="animation-fadeIn">
          <h3 className="text-primary text-[24px] md:text-[30px]">
            Hiểu rõ hơn về Hidiarch tại đây!
          </h3>
        </ScrollAnimation>
        <ScrollAnimation animateIn="mt-0 animate-bounceInRight">
          <div className="h-[1px] w-[200px] bg-black" />
        </ScrollAnimation>
        <p className="text-primary w-full sub-text-size" style={{ fontSize: parseInt(font2) }}>
          Hidiarch ra đời nhằm mang đến những giá trị thẩm mỹ, tối ưu công năng
          cho không gian sống cũng như lan toả niềm đam mê, nhiệt huyết của tuổi
          trẻ, góp phần phát triển và vươn xa hơn cho nền kiến trúc Việt Nam.
        </p>
        <Button fontSize={parseInt(font3)}>Tìm hiểu thêm</Button>
      </div>
      {/* {renderPopup()} */}
    </div>
  );
};

export default Welcome;
