import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { timeAgo } from 'src/utils';
import useFirebase from 'src/hooks/useFirebase';
import { useLoading } from 'src/hooks/useLoading';
import { confirmAlert } from 'react-confirm-alert';
import './alert.css'
import { toast } from 'react-toastify';
import colors from 'src/theme/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const NewIcon = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 5px;
`;

const Container = styled.div`
    flex: 1;
    margin: 10px;
`;

export default function MailPage() {
  const [mails, setMails] = useState([]);
  const { getMails, updateMail, deleteMail } = useFirebase();
  const loading = useLoading();

  const isAdmin = localStorage.getItem('@username') === 'hidiarch.studio@gmail.com';

  async function fetchData() {
    loading.showLoading();
    const data = await getMails();
    setMails(data);
    loading.hideLoading();
  }

  useEffect(() => {
    fetchData();
  }, [])

  const onClick = (mail) => {
    confirmAlert({
      message: mail.status === 'unread' ? 'Đánh dấu là "Đã đọc"?' : 'Đánh dấu là "Chưa đọc"?',
      buttons: [

        {
          label: 'Xác nhận',
          onClick: () => updateMail(mail.id, mail.status === 'unread' ? 'read' : 'unread', () => { toast.error('vui lòng thử lại') }, () => fetchData())

        },
      ]
    });
  }

  const removeMail = (mail) => {
    confirmAlert({
      message: "Xoá mail này?",
      buttons: [

        {
          label: 'Xoá',
          onClick: () => deleteMail(mail.id, () => { toast.error('vui lòng thử lại') }, () => fetchData())

        },
      ]
    });
  }

  return (
    <Container>
      <div style={{ overflow: 'scroll', height: window.innerHeight - 20 }}>
        {mails.length > 0 && mails.map(mail => {
          return (
            <div key={mail.content} style={{ boxShadow: '0px 2px 8px 2px #00000010', backgroundColor: 'white', margin: 0, marginTop: 10, borderRadius: 5, position: 'relative', cursor: 'pointer', overflow: 'hidden' }}>
              {isAdmin && (
                <FontAwesomeIcon
                  icon={faClose}
                  size="sm"
                  color='red'
                  style={{ backgroundColor: 'white', position: 'absolute', top: 10, right: 10, zIndex: 100, width: 20, height: 20, borderRadius: 10, cursor: 'pointer' }}
                  onClick={() => removeMail(mail)}
                />
              )}

              {mail.status === 'unread' && <NewIcon />}
              <div style={{ padding: 20 }} onClick={() => onClick(mail)}>
                <p style={{ color: 'black', fontSize: 16, margin: 0 }}>{mail.subject}</p>
                <p style={{ color: 'black', margin: 0, fontSize: 14 }}>{mail.content}</p>
                <p style={{ color: colors.GREY, margin: 0, fontSize: 14 }}>{mail.name} - {mail.phoneNumber} - {mail.email}</p>
                <p style={{ color: 'grey', fontSize: 10, marginTop: 20 }}>{timeAgo(mail.timestamp)}</p>
              </div>
            </div>
          )
        })}
      </div>

    </Container>
  )
}