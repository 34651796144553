import React, { useState } from "react";
import IMAGE1 from "./images/homepage_service1.jpg";
import IMAGE2 from "./images/homepage_service2.jpg";
import IMAGE3 from "./images/homepage_service3.jpg";
import IMAGE4 from "./images/homepage_service4.jpg";
import Button from "src/components/Button";
import ScrollAnimation from "src/components/ScrollAnimation";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';

const SERVICES = [
  {
    id: 1,
    title: "Kiến trúc xây dựng",
    desc: "This text briefly introduces your services to visitors.",
    img: IMAGE1,
  },
  {
    id: 2,
    title: "Thiết kế nội thất",
    desc: "This text briefly introduces your services to visitors.",
    img: IMAGE2,
  },
  {
    id: 3,
    title: "Thiết kế cải tạo",
    desc: "This text briefly introduces your services to visitors.",
    img: IMAGE3,
  },
  {
    id: 4,
    title: "Thiết kế khác",
    desc: "This text briefly introduces your services to visitors.",
    img: IMAGE4,
  },
];

const Service = (props) => {
  const [font1, setFont1] = useState(24);
  const [font2, setFont2] = useState(14);

  const renderPopup = () => (
    <Popup
      trigger={
        <button className="border bg-black h-max my-auto text-sm text-white py-2 px-8 uppercase transition-colors duration-500 hover:bg-white hover:text-black md:text-left md:text-lg md:leading-7" style={{ position: 'absolute', bottom: 20, right: 20, fontSize: 14 }}>
          Chỉnh fontsize
        </button>}
      modal
    >
      <div>
        <label>
          Tiêu đề:
          <input className="border m-2" name="title" value={font1} onChange={e => setFont1(e.target.value)} />
        </label>
        <br />
        <label>
          Nội dung:
          <input className="border m-2" name="desc" value={font2} onChange={e => setFont2(e.target.value)} />
        </label>
      </div>
    </Popup>
  )
  const renderServices = () => {
    return SERVICES.map((service) => (
      <div
        key={service.id}
        className="group cursor-pointer h-[180px] w-[180px] md:w-[20vw] md:h-[20vw] max-w-[350px] max-h-[350px] m-[6px] md:m-[16px] relative"
      >
        <div>
          <img src={service.img} className="h-[180px] w-[180px] max-w-[350px] max-h-[350px] md:w-[20vw] md:h-[20vw] ra object-cover" alt="" />
        </div>
        <div className="opacity-0 group-hover:opacity-70 w-full absolute bg-white top-0 h-full flex flex-col justify-center p-[8px] md:p-[35px] boxHover text-center py-5">
          <p className="text-primary uppercase mb-2 leading-5" style={{ fontSize: parseInt(font2) }}>
            {service.title}
          </p>
          {/* <p className="text-primary sub-text-size">{service.desc}</p> */}
        </div>
      </div>
    ));
  };
  return (
    <div className="relative h-auto md:h-screen bg-heroBg py-[30px] padding-x-box">
      <div className="container h-full m-auto flex flex-col md:justify-evenly items-center">
        <h1 className="text-center m-[8px] mx-[10vw] uppercase text-[24px] md:text-[40px]">
          <ScrollAnimation animateIn="animation-fadeIn">
            Lĩnh vực hoạt động Hidiarch
          </ScrollAnimation>
        </h1>
        <div className="flex md:flex-row justify-center flex-wrap md:flex-nowrap my-[20px]">
          {renderServices()}
        </div>
        <Button>Tìm hiểu thêm</Button>
      </div>
      {/* {renderPopup()} */}
    </div>
  );
};

export default Service;
