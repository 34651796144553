import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faBook, faTasks } from '@fortawesome/free-solid-svg-icons';
import React, { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function MobileBottomBar() {
  const navigate = useNavigate();
  const textStyle = {margin: 4, color: 'black', fontSize: 12};

  const [index, setIndex] = useState('0');

  useLayoutEffect(() => {
    const index = localStorage.getItem('@selectedIndexBottomBar');
    setIndex(index);
  })

  const onClick = (path, i) => () => {
    localStorage.setItem('@selectedIndexBottomBar', i);
    navigate(path);
  }

  return (
    <div style={{ height: 50, width: '100%', backgroundColor: 'white', position: 'fixed', bottom: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column', cursor: 'pointer', opacity: index === '0' ? 1 : 0.5 }} onClick={onClick('/', 0)}>
        <FontAwesomeIcon icon={faHome} size='sm' />
        <p style={textStyle}>Trang chủ</p>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column', cursor: 'pointer', opacity: index === '1' ? 1 : 0.5 }} onClick={onClick('/introduction', 1)} >
        <FontAwesomeIcon icon={faInfoCircle} size='sm' />
        <p style={textStyle}>Giới thiệu</p>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column', cursor: 'pointer', opacity: index === '2' ? 1 : 0.5 }} onClick={onClick('/projects', 2)} >
        <FontAwesomeIcon icon={faBook} size='sm' />
        <p style={textStyle}>Dự án</p>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column', cursor: 'pointer', opacity: index === '3' ? 1 : 0.5 }} onClick={onClick('/contact', 3)} >
        <FontAwesomeIcon icon={faTasks} size='sm' />
        <p style={textStyle}>Liên hệ</p>
      </div>
    </div>
  )
}