import React from 'react';
import { useNavigate } from 'react-router-dom';
import useMobileOrTablet from 'src/hooks/useMediaQuery';
import styled from 'styled-components';
import HamburgerMenu from '../Hamburger';

const Container = styled.div`
  height: 100px;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

export default function Header() {

  const isMobileOrTablet = useMobileOrTablet();
  const navigate = useNavigate();

  const styles = {
    iconWidth: isMobileOrTablet ? 30 : 50,
    iconHeight: isMobileOrTablet ? 30 : 50,
    titleFontSize: isMobileOrTablet ? 20 : 30
  }

  const openHomepage = () => navigate('/')

  return (
    <Container>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={openHomepage}>
        <img src='https://bio.linkcdn.to/20210809/1628520221869.jpeg' style={{width: styles.iconWidth, height: styles.iconHeight, borderRadius: 25, marginRight: 10}} alt='' />
        <p style={{fontSize: styles.titleFontSize, fontWeight: 700}}>Hidiarch Studio</p>
      </div>
      {!isMobileOrTablet && <HamburgerMenu />}
    </Container>
  )
}