import React, { useEffect, useState } from 'react';
import { PAGE_MAX_WIDTH, PAGE_PADDING } from '../constants';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import useMobileOrTablet from 'src/hooks/useMediaQuery';
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import { scrollToTop } from 'src/utils';
import MobileBottomBar from 'src/components/MobileBottomBar';

const ToTopButton = styled(FontAwesomeIcon)`
    transition: all 0.4s;
    &:hover,
    &:active {
        transform: scale(1.1);
    }
`;

export default function GreyLayout({ children }) {
    const isMobileOrTablet = useMobileOrTablet()
    const [onTop, setOnTop] = useState(true);

    useEffect(() => {
        window.onscroll = () => {
            if (window.pageYOffset <= 50) {
                onTop === false && setOnTop(true)
            } else {
                onTop === true && setOnTop(false)
            }
        }

        return () => (window.onscroll = null);
    });

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flex: 'column',
            }}
        >

            <Helmet>
                <meta charSet="utf-8" />
                <title>Hidiarch Studio</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link
                    href="https://bio.linkcdn.to/20210809/1628520221869.jpeg"
                    rel="icon"
                />
            </Helmet>

            <div style={{
                alignItems: 'center',
                backgroundColor: '#2A3432',
                // backgroundImage: `url(${'https://bio.linkcdn.to/20210809/1628521194649.jpeg'})`,
                backgroundImage: `url(${require('src/assets/images/greyBackground.webp')})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'repeat',
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: -100
            }} />

            <div style={{ maxWidth: PAGE_MAX_WIDTH, padding: PAGE_PADDING, paddingTop: 0, minWidth: 100 }}>
                <Header />
                {children}
                <p style={{textAlign: 'center', opacity: 0}}>-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                <Footer />
            </div>

            {isMobileOrTablet && <MobileBottomBar />}

            {!onTop && <ToTopButton
                icon={faAngleUp}
                onClick={scrollToTop}
                style={{
                    position: 'fixed',
                    bottom: isMobileOrTablet ? 80 : 20,
                    right: isMobileOrTablet ? 20 : 20,
                    backgroundColor: 'white',
                    width: isMobileOrTablet ? 20 : 20,
                    height: isMobileOrTablet ? 20 : 20,
                    padding: 10,
                    borderRadius: 50,
                    overflow: 'hidden',
                    cursor: 'pointer',
                }} />
            }
        </div>
    )
}