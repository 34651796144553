import React, { createContext, useState, useContext } from 'react';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState('');
  const [user, setUser] = useState();
  
  return (
    <AuthContext.Provider value={{ token: token, setToken: setToken, user: user, setUser: setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthContext');
  }

  return context;
}
