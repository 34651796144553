import React from "react";
import NavBar from "./components/Navbar/NavBar";
import Footer from "./components/Footer/Footer";
import { Helmet } from "react-helmet";

const LayoutV2 = (props) => {
  return (
    <div className="max-w-full overflow-y-hidden font-poppins">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hidiarch Studio</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.2, user-scalable=no" />
        <link
          href="https://bio.linkcdn.to/20210809/1628520221869.jpeg"
          rel="icon"
        />
      </Helmet>
      <NavBar />
      {props.children}
      <Footer />
    </div>
  );
};

export default LayoutV2;
