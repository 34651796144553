import React from "react";

export default function PagingBar({ length, currentIndex, onClick, fixed, transparent }) {

  return (
    <div style={{ display: 'flex', flexDirection: 'row', position: fixed ? 'fixed' : 'unset', top: 10 }}>
      {Array(length).fill('dot').map((item, index) => {
        return (
          <div
            key={index}
            style={{
              opacity: transparent ? 0 : 1,
              transition: 'all 0.4s',
              cursor: 'pointer',
              width: index === currentIndex ? 30 : 6,
              height: 6,
              backgroundColor: index === currentIndex ? 'white' : 'grey',
              margin: 6,
              borderRadius: 5
            }}
            onClick={() => { onClick(index) }}
            ></div>
        )
      })}
    </div>
  )
}