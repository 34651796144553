import React, { useEffect, useRef } from "react";
import useMobileOrTablet from "src/hooks/useMediaQuery";
import useStyles from "src/hooks/useStyles";

export default function Photo({ url, isSmall, widthPercent, onClickImage, description }) {
  const ref = useRef(null)
  const marginTop = useMobileOrTablet ? 10 : 20;
  // const [height, setHeight] = useState(100);
  const styles = useStyles();

  useEffect(() => {
    // setHeight(isSmall ? ref.current.clientWidth * 4/3 : ref.current.clientWidth * 3/4);
  })

  return (
    <>
      <img
        onClick={() => onClickImage(url)}
        ref={ref}
        style={{ width: widthPercent ? widthPercent : (isSmall ? '49%' : '100%') , height: 'auto', borderRadius: 8, objectFit: 'cover', marginTop }}
        src={url}
        alt=''
      />
      <p style={{...styles.descriptionStyle}}>{description}</p>
    </>
  )
}