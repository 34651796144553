import React, { useEffect, useMemo, useRef, useState } from 'react';
import '../../../../App.css'
import styled from 'styled-components';
import useStyles from 'src/hooks/useStyles';
import { getGoogleDriveDisplayURL, removeAccents, scrollToTop, scrollToTopWithoutAnimation } from 'src/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import PagingBar from './shared/PagingBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import useMobileOrTablet from 'src/hooks/useMediaQuery';
import { useLoading } from 'src/hooks/useLoading';
import Photo from './shared/Photo';
// import ImgsViewer from "react-images-viewer";
import useFirebase from 'src/hooks/useFirebase';
import RelatedItem from './shared/RelatedItem';
import ImageViewer from 'react-simple-image-viewer';
import { options } from 'src/pages/admin/ManageProjects/ManageProjectDetail';

const NavigationButton = styled(FontAwesomeIcon)`
  transition: all 0.4s;
  &:hover,
  &:active {
      transform: scale(1.1);
  }
`;

const Container = styled.div`
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
`;


export default function ProjectDetailPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const styles = useStyles();
  const { innerHeight: screenHeight } = window;
  const isMobileOrTablet = useMobileOrTablet();
  const { getProjects } = useFirebase()

  const headerRef = useRef(null);

  const loading = useLoading();
  const [projects, setProjects] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [onTop, setOnTop] = useState(true);
  const [viewVisible, setViewVisible] = useState(false);
  const [viewerIndex, setViewerIndex] = useState(0);
  const [relatedContent, setRelatedContent] = useState();
  const [relatedProject, setRelatedProject] = useState([]);
  const [allProjects, setAllProjects] = useState([]);

  const images = useMemo(() => {
    const mainImages = projects[currentIndex]?.mainPhotos?.map(el => getGoogleDriveDisplayURL(el.path)) ?? [];
    const galleryImages = projects[currentIndex]?.galleries?.map(g => g.images)?.flat()?.map(i => getGoogleDriveDisplayURL(i.path)) ?? [];
    return [...mainImages, ...galleryImages]
  }, [projects])

  const goBackImage = () => {
    if (viewerIndex >= 1) {
      setViewerIndex(prev => prev - 1);
    }
  }

  const goNextImage = () => {
    if (viewerIndex < images.length - 1) {
      setViewerIndex(prev => prev + 1)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (window.pageYOffset <= 50) {
        onTop === false && setOnTop(true)
      } else {
        onTop === true && setOnTop(false)
      }
    })

    return () => { window.removeEventListener('scroll', null) };
  });

  /**
   * kind: 'townhouse' | 'apartment' | 'public-construction' | 'actual'
   */
  const { pathname, search } = location;
  const parts = pathname.split('/');
  const kind = parts[parts.length - 1].includes('?') ? parts[parts.length - 1].includes('?').split('?')[0] : parts[parts.length - 1];
  const projectName = decodeURIComponent(search.replace('?', ''));

  useEffect(() => {
    scrollToTopWithoutAnimation()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 0);
    async function fetchData() {
      loading.showLoading();
      const data = await getProjects(kind);
      let list = data.filter(el => !el.hidden);

      if (projectName) {
        list = list.filter(el => el.name === projectName)
      }
      setProjects(list);
      loading.hideLoading();
    }
    async function fetchPreviewProject() {
      loading.showLoading();
      const previewProject = localStorage.getItem('@previewProject');
      setProjects([JSON.parse(previewProject)]);
      loading.hideLoading();
    }
    search.includes('preview') ? fetchPreviewProject() : fetchData();
  }, [kind, search, currentIndex, projectName]);

  useEffect(() => {
    async function fetchAllProjects() {
      let results = [];
      for (let i = 0; i < options.length; i++) {
        const data = await getProjects(options[i].value);
        results = [...results, ...data];
      }
      setAllProjects(results);
    }
    fetchAllProjects();
  }, []);

  useEffect(() => {
    function getRelatedProjects() {
      const relatedProjects = projects[currentIndex]?.relatedProjects ?? [];
      const shouldCollect = (project) => relatedProjects.findIndex(item =>  removeAccents(project.name) === removeAccents(item.name)) !== -1
      setRelatedProject(allProjects.filter(shouldCollect));
    }
    getRelatedProjects();
  }, [currentIndex, projects, allProjects]);

  const onBack = () => {
    if (currentIndex >= 1) {
      setCurrentIndex(prev => prev - 1)
    }
  }

  const onNext = () => {
    if (currentIndex < projects.length - 1) {
      setCurrentIndex(prev => prev + 1)
    }
  }

  const onClickImage = (url) => {
    setViewerIndex(images?.findIndex(item => item === url));
    setViewVisible(true);
  };

  //#region Swipe hanlder
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 150

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (!viewVisible) {
      if (isLeftSwipe || isRightSwipe) {
        isLeftSwipe ? onNext() : onBack()
      }
    }
  }
  //#endregion

  const onMouseEnter = (data) => {
    relatedContent === undefined && setRelatedContent(data);
    // setViewVisible(true)
    // alert('mouse enter');
  }

  const onMouseLeave = () => {
    relatedContent && setRelatedContent(undefined);
    // alert('mouse leave');
    // setViewVisible(true)
  }

  const onClickRelatedPost = async (post) => {
    const nextPath = pathname.split('/');
    nextPath.pop();
    if (post.category !== 'townhouse' && post.category !== 'apartment') {
      nextPath.pop();
    }
    navigate(`${nextPath.join('/')}/${post.category}?${post.name}`);
  }

  return (
    <Container onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      <PagingBar
        length={projects.length}
        transparent={!onTop}
        currentIndex={currentIndex}
        onClick={index => setCurrentIndex(index)}
        fixed={false} />

      <PagingBar
        length={projects.length}
        transparent={onTop}
        currentIndex={currentIndex}
        onClick={index => setCurrentIndex(index)}
        fixed={true} />
      {projects && projects.length > 1 && !viewVisible && (
        <>
          <div style={{ position: 'absolute', left: isMobileOrTablet ? 25 : -20, marginLeft: -40 }}>
            <NavigationButton
              icon={faAngleLeft}
              style={{
                position: 'fixed',
                cursor: 'pointer',
                top: screenHeight / 2,
                padding: 10,
                backgroundColor: currentIndex === 0 ? 'grey' : 'white',
                borderRadius: 40,
                width: 20,
                height: 20,
                opacity: 0.7,
                zIndex: 100
              }}
              size="2x"
              onClick={onBack}
            />
          </div>

          <div style={{ position: 'absolute', right: isMobileOrTablet ? 25 : -20 }}>
            <NavigationButton
              icon={faAngleRight}
              style={{
                position: 'fixed',
                cursor: 'pointer',
                top: screenHeight / 2,
                padding: 10,
                backgroundColor: currentIndex === projects.length - 1 ? 'grey' : 'white',
                borderRadius: 40,
                width: 20,
                height: 20,
                opacity: 0.7,
                zIndex: 100
              }}
              size="2x"
              onClick={onNext}
            />
          </div>
        </>
      )}

      <div style={{ minHeight: screenHeight }}>
        <div ref={headerRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p style={{ ...styles.titleStyle, textAlign: 'center', marginTop: 50, marginBottom: 20, fontSize: 40, textTransform: 'capitalize' }}>{projects[currentIndex]?.name}</p>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 10 }}>
          {projects[currentIndex]?.mainPhotos?.map((image, index) => {
            return <Photo key={image.path} url={getGoogleDriveDisplayURL(image.path)} description={image.description} onClickImage={onClickImage} isSmall={image.size === 'small'} widthPercent={index !== 0 ? '32.2%' : undefined} />
          })}
        </div>

        <div ref={headerRef} style={{ display: 'flex', flexDirection: 'column', marginTop: 50 }}>
          {projects[currentIndex]?.description?.length > 0 && projects[currentIndex]?.description?.map(description => {
            return <p style={{ ...styles.descriptionStyle, marginTop: 0 }}>{`${description}`}</p>
          })}
        </div>

        {projects[currentIndex]?.galleries?.length > 0 ? projects[currentIndex]?.galleries.map(gallery => {
          return (
            <div style={{ marginTop: 80 }} key={gallery.title}>
              <p style={{ ...styles.titleStyle, textAlign: 'center' }}>{gallery.title}</p>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                {gallery.images.length > 0 && gallery.images.map(image => {
                  return <Photo key={image.path} url={getGoogleDriveDisplayURL(image.path)} description={image.description} onClickImage={onClickImage} isSmall={image.size === 'small'} />
                })}
              </div>
            </div>
          )
        }) : <div style={{ height: 1000 }}></div>}
      </div>

      {/* related projects */}
      {relatedProject.length > 0 && (
        <div style={{ width: '100%' }}>
          <p style={{ marginTop: 100, alignSelf: 'flex-start' }}>Nội dung liên quan</p>
          <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
            <Modal style={{ opacity: relatedContent ? 1 : 0, marginTop: isMobileOrTablet ? -200 : -300 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                {relatedContent?.mainPhotos?.map((image, index) => {
                  return <Photo key={image.path} url={getGoogleDriveDisplayURL(image.path)} description={image.description} onClickImage={onClickImage} isSmall={image.size === 'small'} widthPercent={index !== 0 ? '32.2%' : undefined} />
                })}
              </div>
            </Modal>
            <RelatedItem key={relatedProject[0]?.name} onClick={onClickRelatedPost} item={relatedProject?.[0]} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
            <RelatedItem key={relatedProject[1]?.name} onClick={onClickRelatedPost} item={relatedProject?.[1]} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
            <RelatedItem key={relatedProject[2]?.name} onClick={onClickRelatedPost} item={relatedProject?.[2]} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
          </div>
        </div>
      )}


      {/* <ImgsViewer
        imgs={images}
        currImg={viewerIndex}
        isOpen={viewVisible}
        onClickPrev={goBackImage}
        onClickNext={goNextImage}
        onClose={() => setViewVisible(false)}
      /> */}
      {viewVisible && (
        <ImageViewer
          currentIndex={viewerIndex}
          src={images}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setViewVisible(false)}
          rightArrowComponent={
            <Button icon={faAngleRight}
              onClick={goNextImage}
              style={{
                borderRadius: 50,
                cursor: 'pointer',
                marginRight: isMobileOrTablet ? 0 : 50
              }}
            />}
          leftArrowComponent={
            <Button icon={faAngleLeft}
              onClick={goBackImage}
              style={{
                borderRadius: 50,
                cursor: 'pointer',
                marginLeft: isMobileOrTablet ? 0 : 50
              }}
            />}
        />
      )}

    </Container>
  )
}

const Button = styled(FontAwesomeIcon)`
    transition: all 0.4s;
    &:hover,
    &:active {
        transform: scale(1.1);
    }
`;

const Modal = styled.div`
  width: 100%;
  /* margin-left: 20%;
  margin-right: 20%; */
  z-index: 100;
  height: 100%;
  margin-top: 0;
  position: absolute;
  border-radius: 5px;
  display: flex;
  pointer-events: none;
  transition: all 0.4s;
`;