import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImgsViewer from 'react-images-viewer';
import { storage } from 'src/firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { toast } from 'react-toastify';
import useFirebase from 'src/hooks/useFirebase';
import { useLoading } from 'src/hooks/useLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
    flex: 1;
    margin: 10px;
`;

export default function GalleryPage() {
  const [viewVisible, setViewVisible] = useState(false);
  const [viewerIndex, setViewerIndex] = useState(0);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [photoList, setPhotoList] = useState([]);
  const { getPhotos, addPhoto } = useFirebase();
  const loading = useLoading();

  useEffect(() => {
    uploadingFiles.length > 0 && loading.showLoading();
    uploadingFiles.length === 0 && loading.hideLoading();
  }, [uploadingFiles])

  useEffect(() => {
    async function fetchData() {
      loading.showLoading();
      const photos = await getPhotos();
      setPhotoList(photos);
      loading.hideLoading();
    }
    fetchData()
  }, [])

  const goBackImage = () => {
    if (viewerIndex >= 1) {
      setViewerIndex(prev => prev - 1);
    }
  }

  const goNextImage = () => {
    if (viewerIndex < photoList.length - 1) {
      setViewerIndex(prev => prev + 1)
    }
  }

  const onClickImage = (url) => {
    setViewerIndex(photoList?.findIndex(item => item.url === url));
    setViewVisible(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    let fileList = Object.keys(e.target[0]?.files).map(key => e.target[0]?.files[key]);
    setUploadingFiles(fileList);

    fileList.forEach(file => {
      if (!file) return;
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on("state_changed",
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          if (progress >= 100) {
            toast.success(`Tải lên ${file.name} thành công`);
          }
        },
        (error) => {
          toast.success(`Tải lên ${file.name} không thành công`);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const newPhoto = { url: downloadURL, timestamp: Date.now(), name: file.name }
            addPhoto(newPhoto);
            setPhotoList(prev => [newPhoto, ...prev]);
            setUploadingFiles(prev => prev.filter(item => item.name !== file.name))
          });
        }
      );
    })
  }

  const onCopy = (url) => {
    navigator.clipboard.writeText(url);
    toast.success('Đã sao chép link ảnh');
  }

  return (
    <Container>
      <form style={{ margin: 15 }} onSubmit={handleSubmit} className='form'>
        <input type='file' multiple />
        <button type='submit'>Tải lên</button>
      </form>
      <div style={{ overflow: 'scroll', height: window.innerHeight - 100 }}>
        <div style={{ marginTop: 20, display: 'flex', flexWrap: 'wrap' }}>
          {photoList && photoList.length > 0 && photoList.map(photo => {
            return (
              <div key={photo.url} style={{
                width: 200,
                height: 200,
                position: 'relative',
                overflow: 'hidden',
                cursor: 'pointer',
                margin: 5,
                borderRadius: 5,
                backgroundImage: `url(${require('../../../assets/images/placeholder-image.png')})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }} >
                <img
                  onClick={() => onClickImage(photo.url)}
                  style={{ width: 200, height: 200, objectFit: 'cover', overflow: 'hidden' }}
                  src={photo.url}
                  sizes=''
                  alt=""
                />
                <FontAwesomeIcon icon={faCopy} style={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }} onClick={() => onCopy(photo.url)} />
              </div>
            )
          })}
        </div>
      </div>

      <ImgsViewer
        imgs={photoList.map(file => ({ src: file.url }))}
        currImg={viewerIndex}
        isOpen={viewVisible}
        onClickPrev={goBackImage}
        onClickNext={goNextImage}
        onClose={() => setViewVisible(false)}
      />
    </Container>
  )
}