import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Dropdown from 'react-dropdown';
import useStyles from 'src/hooks/useStyles';
import './style.css';
import { getGoogleDriveDisplayURL, isEmpty, scrollToTop } from 'src/utils';
import { toast } from 'react-toastify';
import { useLoading } from 'src/hooks/useLoading';
import useFirebase from 'src/hooks/useFirebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  // ref, getDownloadURL, uploadBytesResumable,
  //deleteObject, listAll 
} from "firebase/storage";
// import { storage } from 'src/firebase';
// import Resizer from "react-image-file-resizer";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export const options = [
  { label: 'Thiết kế nhà phố', value: 'townhouse' },
  { label: 'Thiết kế chung cư', value: 'apartment' },
  { label: 'Công trình công cộng', value: 'public-construction' },
  { label: 'Thi công thực tế', value: 'actual' },
];

export default function ManageProjectDetailPage(props) {
  const location = useLocation();

  const navigate = useNavigate();
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [descriptions, setDescriptions] = useState();
  // const [details, setDetails] = useState();
  const [category, setCategory] = useState();
  const [galleries, setGalleries] = useState([{
    title: '',
    images: [{
      path: '',
      size: 'large'
    }]
  }]);
  const [allProjects, setAllProjects] = useState([]);
  const [relatedProjects, setRelatedProjects] = useState([{}]);
  const [mainPhotos, setMainPhotos] = useState([]);

  const styles = useStyles();
  const loading = useLoading();
  const { addProject, deleteProject, updateProject, getProjects } = useFirebase();

  const isAdmin = localStorage.getItem('@username') === 'hidiarch.studio@gmail.com';

  useEffect(() => {
    const project = location.state?.project;
    if (project) {
      setId(project.id)
      setName(project.name);
      setDescriptions(project.description.join('\n'));
      // setDetails(project.details.join('\n'));
      setCategory(project.category);
      setGalleries(project.galleries)
      setRelatedProjects(project.relatedProjects ?? []);
      setMainPhotos(project.mainPhotos ?? []);
    }
  }, [location]);

  useEffect(() => {
    async function fetchData() {
      let results = [];
      for (let i = 0; i < options.length; i++) {
        const data = await getProjects(options[i].value);
        results = [...results, ...data];
      }
      setAllProjects(results);
    }
    fetchData();
  }, []);

  const onPreview = () => {
    const previewProject = {
      name,
      description: descriptions?.trim().split('\n'),
      galleries,
      category,
      relatedProjects,
      mainPhotos
    }
    localStorage.setItem('@previewProject', JSON.stringify(previewProject));
    let categoryPath = '';
    if (category === 'townhouse' || category === 'apartment') {
      categoryPath = 'civil-construction/';
    }
    categoryPath += category;
    const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    window.open(`${development ? 'http://localhost:3000' : 'https://hidiarchstudio.com'}/projects/${categoryPath}?preview`);
  }

  const onSave = async () => {
    const edit = location.state?.project !== undefined;

    try {
      if (isEmpty(category?.trim())) return toast.error('Chủ đề không được bỏ trống');
      if (isEmpty(name?.trim())) return toast.error('Tên dự án không được bỏ trống');
      if (isEmpty(descriptions?.trim())) return toast.error('Mô tả không được bỏ trống');
      // if (isEmpty(details?.trim())) return toast.error('Chi tiết không được bỏ trống');

      const project = {
        name,
        description: descriptions?.trim().split('\n'),
        // details: details?.trim().split('\n'),
        galleries,
        category,
        relatedProjects,
        mainPhotos
      }
      loading.showLoading();
      if (edit) {
        await updateProject(project, id,
          () => { toast.error('Đã xảy ra lỗi vui lòng thử lại') },
          () => {
            toast.success('Cập nhật dự án thành công');
            navigate('/admin/projects');
            updateSuccess();
          }
        );
      } else {
        await addProject(project,
          (e) => { toast.error('Đã xảy ra lỗi vui lòng thử lại ', e) },
          () => {
            toast.success('Thêm dự án thành công');
            navigate('/admin/projects');
            updateSuccess();
          }
        );
      }
      loading.hideLoading();
    } catch (e) { }
  }

  const updateSuccess = async () => {
    // removeUnusedImages();
    scrollToTop();
    setName(undefined);
    setDescriptions(undefined);
    // setDetails(undefined);
    setCategory(undefined);
    setGalleries([{
      title: '',
      images: [{
        path: '',
        size: 'large'
      }]
    }]);
    setRelatedProjects([{}]);
    setMainPhotos([]);
  }

  // const deleteFile = async (value) => {
  //   const pictureRef = ref(storage, value)
  //   deleteObject(pictureRef).then(() => { }).catch(e => { console.log(e) })
  // }

  // const removeUnusedImages = async () => {
  //   const usingImages = galleries.reduce((acc, gallery) => {
  //     if (gallery.images) {
  //       gallery.images.forEach(image => {
  //         if (image.ref) {
  //           return acc.push(image.ref);
  //         }
  //       });
  //     }
  //     return acc;
  //   }, [])

  //   const listRef = ref(storage, name);
  //   let refList = [];
  //   listAll(listRef)
  //     .then((res) => {
  //       res.items.forEach((itemRef) => {
  //         refList.push(itemRef['_location']['path_']);
  //       });
  //       const shouldDeletedList = refList.filter(item => !usingImages.includes(item));
  //       Promise.all(shouldDeletedList.map(item => deleteFile(item)))
  //     }).catch((error) => { });

  //   return;
  // }

  const onDelete = () => {
    if (isAdmin) {
      deleteProject(category, id,
        () => { toast.error('Đã xảy ra lỗi vui lòng thử lại') },
        () => {
          toast.success('Xoá dự án thành công');
          navigate('/admin/projects');
        })
    } else {
      const project = {
        name,
        description: descriptions?.trim().split('\n'),
        // details: details?.trim().split('\n'),
        galleries,
        category
      }
      updateProject({ ...project, hidden: true }, id,
        () => { toast.error('Đã xảy ra lỗi vui lòng thử lại') },
        () => {
          toast.success('Xoá dự án thành công');
          navigate('/admin/projects');
        }
      );
    }
  }

  const onRecover = () => {
    updateProject({
      name,
      description: descriptions?.trim().split('\n'),
      // details: details?.trim().split('\n'),
      mainPhotos,
      galleries,
      category,
      relatedProjects,
      hidden: false,
    }, id,
      () => { toast.error('Đã xảy ra lỗi vui lòng thử lại') },
      () => {
        toast.success('Khôi phục dự án thành công');
        navigate('/admin/projects');
      }
    );
  }

  const onAddCategory = () => {
    setGalleries([...galleries, { title: '', images: [{ path: '', size: 'large' }] }])
  }

  const onCategoryNameChange = (value, index) => {
    setGalleries(galleries.map((g, i) => i === index ? { ...g, title: value } : g));
  }

  const onCategoryImageChange = (value, cIndex, iIndex) => {
    setGalleries(galleries.map((g, index) => index === cIndex ? { ...g, images: g.images.map((i, index) => index === iIndex ? { ...i, path: value } : i) } : g));
  };

  const onChangeMainImage = (value, cIndex) => {
    let newMainPhotos = [...mainPhotos];
    newMainPhotos[cIndex] = { ...(newMainPhotos[cIndex] ?? {}), path: value };
    setMainPhotos(newMainPhotos);
  };

  const onCategoryImageContentChange = (value, cIndex, iIndex) => {
    setGalleries(galleries.map((g, index) => index === cIndex ? { ...g, images: g.images.map((i, index) => index === iIndex ? { ...i, description: value } : i) } : g));
  };

  const onRemoveImage = async (value, cIndex, iIndex) => {
    setGalleries(galleries.map((g, index) => index === cIndex ? { ...g, images: g.images.map((i, index) => index === iIndex ? { ...i, path: '', ref: '' } : i) } : g));
  }

  // const getUploadedFileDimensions = (file) => new Promise((resolve, reject) => {
  //   try {
  //     let img = new Image()
  //     img.onload = () => {
  //       const width = img.naturalWidth,
  //         height = img.naturalHeight
  //       window.URL.revokeObjectURL(img.src)
  //       return resolve({ width, height })
  //     }

  //     img.src = window.URL.createObjectURL(file)
  //   } catch (exception) {
  //     return reject(exception)
  //   }
  // })

  // const resizeFile = (file) => {
  //   return getUploadedFileDimensions(file).then(({ width, height }) => {
  //     let maxWidth, maxHeight;
  //     if (width > height) {
  //       maxWidth = 1280;
  //       maxHeight = 720;
  //     } else if (width < height) {
  //       maxWidth = 720;
  //       maxHeight = 1280;
  //     } else {
  //       maxWidth = 1080;
  //       maxHeight = 1080;
  //     }
  //     return new Promise((resolve) => {
  //       Resizer.imageFileResizer(
  //         file,
  //         maxWidth,
  //         maxHeight,
  //         "JPEG",
  //         100,
  //         0,
  //         (uri) => {
  //           resolve(uri);
  //         },
  //         "file"
  //       );
  //     });
  //   })

  // }

  // const onPickImage = (value, cIndex, iIndex) => {
  //   [value].forEach(file => {
  //     if (!file) return;
  //     resizeFile(file).then(
  //       (resizedFile) => {
  //         const path = name + '/' + resizedFile.name;
  //         const storageRef = ref(storage, path);
  //         const uploadTask = uploadBytesResumable(storageRef, resizedFile);
  //         loading.showLoading();

  //         uploadTask.on("state_changed",
  //           (snapshot) => {
  //             const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  //             if (progress >= 100) {
  //               toast.success(`Tải lên ${resizedFile.name} thành công`);
  //             }
  //           },
  //           (error) => {
  //             toast.success(`Tải lên ${resizedFile.name} không thành công`);
  //           },
  //           () => {
  //             getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //               // const newPhoto = { url: downloadURL, timestamp: Date.now(), name: file.name }
  //               setGalleries(galleries.map((g, index) => {
  //                 if (index === cIndex) {
  //                   return {
  //                     ...g, images: g.images.map((i, index) => {
  //                       if (index === iIndex) {
  //                         return { ...i, path: downloadURL, ref: path }
  //                       } else {
  //                         return i
  //                       }
  //                     })
  //                   }
  //                 } else {
  //                   return g
  //                 }
  //               }))
  //               loading.hideLoading();
  //             });
  //           }
  //         );
  //       }
  //     ).catch((e) => { console.log(e) });


  //   })

  // };

  // const onPickMainImage = async (value, cIndex) => {
  //   [value].forEach(file => {
  //     if (!file) return;
  //     resizeFile(file).then(
  //       (resizedFile) => {
  //         const path = name + '/' + resizedFile.name;
  //         const storageRef = ref(storage, path);
  //         const uploadTask = uploadBytesResumable(storageRef, resizedFile);
  //         loading.showLoading();

  //         uploadTask.on("state_changed",
  //           (snapshot) => {
  //             const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  //             if (progress >= 100) {
  //               toast.success(`Tải lên ${resizedFile.name} thành công`);
  //             }
  //           },
  //           (error) => {
  //             toast.success(`Tải lên ${resizedFile.name} không thành công`);
  //           },
  //           () => {
  //             getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //               let newMainPhotos = [...mainPhotos];
  //               newMainPhotos[cIndex] = { path: downloadURL, ref: path };
  //               setMainPhotos(newMainPhotos)
  //               loading.hideLoading();
  //             });
  //           }
  //         );
  //       }
  //     ).catch((e) => { console.log(e) });


  //   })

  // };

  const onCategoryImageSizeChange = (checked, cIndex, iIndex) => {
    setGalleries(galleries.map((g, index) => index === cIndex ? { ...g, images: g.images.map((i, index) => index === iIndex ? { ...i, size: checked ? 'small' : 'large' } : i) } : g))
  }

  const onCategoryContentChecked = (checked, cIndex, iIndex) => {
    if (checked) {

    }
    setGalleries(galleries.map((g, index) => index === cIndex ? { ...g, images: g.images.map((i, index) => index === iIndex ? { ...i, hasDescription: checked ? true : false, description: checked ? i.description : '' } : i) } : g))
  }

  const onDeleteImage = (cIndex, iIndex) => {
    setGalleries(galleries.map((g, index) => index === cIndex ? { ...g, images: g.images.filter((item, i) => i !== iIndex) } : g))
  }

  const onDeleteMainImage = (cIndex) => {
    let newMainPhotos = [...mainPhotos];
    newMainPhotos[cIndex] = { ...(newMainPhotos[cIndex] ?? {}), path: '', ref: '' };
    setMainPhotos(newMainPhotos);
  }

  const onDeleteCategory = (cIndex) => {
    setGalleries(galleries.filter((item, i) => i !== cIndex))
  }

  const onAddRelatedProject = () => {
    if (relatedProjects?.length === 3) {
      toast.error('Tối đa 3 bài viết');
      return;
    }
    setRelatedProjects([...relatedProjects, {}])
  }

  const onDeleteRelatedProject = (cIndex) => {
    setRelatedProjects(relatedProjects.filter((item, i) => i !== cIndex))
  }

  const onRelatedProjectChange = (name, index) => {
    setRelatedProjects(relatedProjects.map((g, i) => {
      if (i === index) {
        const found = allProjects.find(el => el.name === name)
        return { name: found.name, category: found.category, mainPhotos: found.mainPhotos };
      } else {
        return g
      }
    }))
  }

  const onAddImage = (cIndex) => {
    setGalleries(galleries.map((g, index) => index === cIndex ? { ...g, images: [...g.images, { path: '', size: 'large' }] } : g))
  }

  const getCategoryName = (category) => {
    return options.find(el => el.value === category)?.label;
  }

  const handleDrop = async (droppedItem) => {
    if (!droppedItem.destination) return;

    const indexOfGallery = galleries.findIndex(gallery => gallery.images.findIndex(image => image.path === droppedItem.draggableId) !== -1)

    var updatedList = [...galleries[indexOfGallery].images];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    var newGalleries = [...galleries];
    newGalleries[indexOfGallery] = {...newGalleries[indexOfGallery] , images: updatedList};
    console.log('oldGalleries ', galleries)
    console.log('newGalleries ', newGalleries)
    setGalleries(newGalleries);
    // Request API
    loading.showLoading();
    // set state
    loading.hideLoading();
  } 

  return (
    <Container>
      <div style={{ backgroundColor: '#bebebe', padding: 10, borderRadius: 5, display: 'flex', flexDirection: 'column', marginBottom: 50 }}>
        <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, marginBottom: 2, color: 'black', fontSize: 16 }}>Chủ đề:</p>
        <div style={{ marginBottom: 20 }}>
          <Dropdown options={options} onChange={(e) => setCategory(e.value)} value={category} placeholder="Chọn chủ đề" />
        </div>

        <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, marginBottom: 2, color: 'black', fontSize: 16 }}>Tên dự án:</p>
        <input style={{ ...styles.descriptionStyle, height: 40, margin: 0, paddingLeft: 10, borderWidth: 0, borderRadius: 5, marginBottom: 20 }} value={name} onChange={e => setName(e.target.value)} />

        <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, marginBottom: 2, color: 'black', fontSize: 16 }}>Mô tả:</p>
        <textarea
          style={{ ...styles.descriptionStyle, boderWidth: 1, border: 'solid 1px', borderColor: 'white', height: 150, margin: 0, padding:  10, borderWidth: 0, borderRadius: 5, marginBottom: 50 }}
          value={descriptions}
          onChange={e => setDescriptions(e.target.value)}
        />

        <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, marginBottom: 2, color: 'black', fontSize: 16 }}>Chi tiết:</p>
        {/* <textarea
          style={{ ...styles.descriptionStyle, boderWidth: 1, border: 'solid 1px', borderColor: 'white', height: 150, margin: 0, padding: 10, borderWidth: 0, borderRadius: 5, marginBottom: 20 }}
          value={details}
          onChange={e => setDetails(e.target.value)}
        /> */}

        {/* main 4 photos */}
        <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, marginBottom: 2, color: 'black', fontSize: 16 }}>Ảnh chính:</p>
        {
          [1, 2, 3, 4].map((item, cIndex) => {
            const image = mainPhotos?.[cIndex] ?? {};
            return (
              <div key={cIndex.toString()} style={{ display: 'flex', position: 'relative', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'center', borderWidth: 1, borderColor: 'black', borderStyle: 'solid', borderRadius: 5, marginBottom: 5, padding: 5, minHeight: 80 }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <input style={{ ...styles.descriptionStyle, height: 40, margin: 0, marginRight: 20, paddingLeft: 10, borderWidth: 0, borderRadius: 5, flex: 1, fontSize: 16, marginBottom: 10 }} value={image.path} onChange={e => onChangeMainImage(e.target.value, cIndex)} placeholder="copy link từ google drive dán vào đây" />

                  {image.path ? (
                    <div style={{ position: 'relative' }}>
                      <FontAwesomeIcon icon={faClose} size="sm" color='red' style={{ backgroundColor: 'white', position: 'absolute', top: 0, left: 100, zIndex: 10, width: 20, height: 20, borderRadius: 10, cursor: 'pointer' }}
                        onClick={() => onDeleteMainImage(cIndex)}
                      />
                      <img style={{ height: 100, width: 100, borderRadius: 5, margin: 10, objectFit: 'cover' }} referrerPolicy='no-referrer' src={getGoogleDriveDisplayURL(image.path)} alt="" />
                    </div>
                  ) : (
                    // <input id="upload" type="file" accept="image/*"
                    //   onChange={(event) => {
                    //     onPickMainImage(event.target.files[0], cIndex)
                    //   }} />
                    <></>
                  )}
                </div>
              </div>
            )
          })
        }
      </div>

      {/* gallery */}
      {galleries.map((gallery, cIndex) => {
        return (
          <div key={cIndex.toString()} style={{ position: 'relative', backgroundColor: '#bebebe', padding: 10, marginBottom: 10, borderRadius: 5, display: 'flex', flexDirection: 'column' }}>
            <FontAwesomeIcon icon={faClose} size="1x" color='red' style={{ backgroundColor: 'white', position: 'absolute', top: -4, right: -4, zIndex: 10, width: 20, height: 20, borderRadius: 10, cursor: 'pointer' }} onClick={() => onDeleteCategory(cIndex)} />
            <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, marginBottom: 2, color: 'black', fontSize: 16 }}>Tên album:</p>
            <input style={{ ...styles.descriptionStyle, height: 40, margin: 0, paddingLeft: 10, borderWidth: 0, borderRadius: 5 }} value={gallery.title} onChange={e => onCategoryNameChange(e.target.value, cIndex)} />
            <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, marginBottom: 2, color: 'black', fontSize: 16 }}>Danh sách ảnh:</p>
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="list-container">
                {(provided) => (
                  <div
                    className="list-container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {(gallery.images ?? []).map((image, iIndex) => (
                      <Draggable key={image.path} draggableId={image.path} index={iIndex}>
                        {(provided) => (
                          <div
                            className="item-container"
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <div key={iIndex.toString()} style={{ display: 'flex', position: 'relative', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'center', borderWidth: 1, borderColor: 'black', borderStyle: 'solid', borderRadius: 5, marginBottom: 5, padding: 5, minHeight: 80 }}>
                              <FontAwesomeIcon icon={faClose} size="1x" color='red' style={{ backgroundColor: 'white', position: 'absolute', top: -4, right: -4, zIndex: 10, width: 20, height: 20, borderRadius: 10, cursor: 'pointer' }} onClick={() => onDeleteImage(cIndex, iIndex)} />
                              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <input style={{ ...styles.descriptionStyle, height: 40, margin: 0, marginRight: 20, paddingLeft: 10, borderWidth: 0, borderRadius: 5, flex: 1, fontSize: 16, marginBottom: 10 }} value={image.path} onChange={e => onCategoryImageChange(e.target.value, cIndex, iIndex)} placeholder="copy link từ google drive dán vào đây" />

                                {image.path ? (
                                  <div style={{ position: 'relative' }}>
                                    <FontAwesomeIcon icon={faClose} size="sm" color='red' style={{ backgroundColor: 'white', position: 'absolute', top: 0, left: 100, zIndex: 10, width: 20, height: 20, borderRadius: 10, cursor: 'pointer' }}
                                      onClick={() => onRemoveImage(image.ref, cIndex, iIndex)} />
                                    <img style={{ height: 100, width: 100, borderRadius: 5, margin: 10, objectFit: 'cover' }} referrerPolicy="no-referrer" src={getGoogleDriveDisplayURL(image.path)} alt="" />
                                  </div>
                                ) : (
                                  // <input id="upload" type="file" accept="image/*"
                                  //   onChange={(event) => {
                                  //     onPickImage(event.target.files[0], cIndex, iIndex)
                                  //   }} />
                                  <></>
                                )}

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <input type="checkbox" title="asdas" checked={image.size === 'small'} onChange={(e) => onCategoryImageSizeChange(e.target.checked, cIndex, iIndex)} />
                                  <p style={{ fontSize: 16, margin: 4 }}>Size nhỏ</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <input type="checkbox" title="asdas"  checked={image.hasDescription === true} onChange={(e) => onCategoryContentChecked(e.target.checked, cIndex, iIndex)} />
                                  <p style={{ fontSize: 16, margin: 4 }}>Thêm nội dung</p>
                                </div>
                                {image.hasDescription && <textarea style={{ ...styles.descriptionStyle, fontSize: 16, borderRadius: 5, borderColor: 'transparent' }} rows="2" value={image.description} onChange={e => onCategoryImageContentChange(e.target.value, cIndex, iIndex)} placeholder="Nội dung"></textarea>}
                                
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* {
              (gallery.images ?? []).map((image, iIndex) => {
                return (
                  <div key={iIndex.toString()} style={{ display: 'flex', position: 'relative', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'center', borderWidth: 1, borderColor: 'black', borderStyle: 'solid', borderRadius: 5, marginBottom: 5, padding: 5, minHeight: 80 }}>
                    <FontAwesomeIcon icon={faClose} size="1x" color='red' style={{ backgroundColor: 'white', position: 'absolute', top: -4, right: -4, zIndex: 10, width: 20, height: 20, borderRadius: 10, cursor: 'pointer' }} onClick={() => onDeleteImage(cIndex, iIndex)} />
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <input style={{ ...styles.descriptionStyle, height: 40, margin: 0, marginRight: 20, paddingLeft: 10, borderWidth: 0, borderRadius: 5, flex: 1, fontSize: 16, marginBottom: 10 }} value={image.path} onChange={e => onCategoryImageChange(e.target.value, cIndex, iIndex)} placeholder="copy link từ google drive dán vào đây" />

                      {image.path ? (
                        <div style={{ position: 'relative' }}>
                          <FontAwesomeIcon icon={faClose} size="sm" color='red' style={{ backgroundColor: 'white', position: 'absolute', top: 0, left: 100, zIndex: 10, width: 20, height: 20, borderRadius: 10, cursor: 'pointer' }}
                            onClick={() => onRemoveImage(image.ref, cIndex, iIndex)} />
                          <img style={{ height: 100, width: 100, borderRadius: 5, margin: 10, objectFit: 'cover' }} referrerPolicy="no-referrer" src={getGoogleDriveDisplayURL(image.path)} alt="" />
                        </div>
                      ) : (
                        // <input id="upload" type="file" accept="image/*"
                        //   onChange={(event) => {
                        //     onPickImage(event.target.files[0], cIndex, iIndex)
                        //   }} />
                        <></>
                      )}

                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <input type="checkbox" title="asdas" checked={image.size === 'small'} onChange={(e) => onCategoryImageSizeChange(e.target.checked, cIndex, iIndex)} />
                        <p style={{ fontSize: 16, margin: 4 }}>Size nhỏ</p>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <input type="checkbox" title="asdas"  checked={image.hasDescription === true} onChange={(e) => onCategoryContentChecked(e.target.checked, cIndex, iIndex)} />
                        <p style={{ fontSize: 16, margin: 4 }}>Thêm nội dung</p>
                      </div>
                      {image.hasDescription && <textarea style={{ ...styles.descriptionStyle, fontSize: 16, borderRadius: 5, borderColor: 'transparent' }} rows="2" value={image.description} onChange={e => onCategoryImageContentChange(e.target.value, cIndex, iIndex)} placeholder="Nội dung"></textarea>}
                      
                    </div>
                  </div>
                )
              })
            } */}
            <AddImageButton onClick={() => onAddImage(cIndex)}>+ Thêm ảnh</AddImageButton>
          </div>
        )
      })}
      <AddGalleryButton onClick={onAddCategory}>+ Thêm album</AddGalleryButton>

      {/* related post */}
      <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, marginBottom: 2, color: 'black', fontSize: 16, marginTop: 50 }}>Bài viết liên quan:</p>
      {relatedProjects?.map((relatedProj, cIndex) => {
        return (
          <div key={cIndex.toString()} style={{ position: 'relative', backgroundColor: '#bebebe', padding: 10, marginBottom: 10, borderRadius: 5, display: 'flex', flexDirection: 'column' }}>
            <FontAwesomeIcon icon={faClose} size="1x" color='red' style={{ backgroundColor: 'white', position: 'absolute', top: -4, right: -4, zIndex: 10, width: 20, height: 20, borderRadius: 10, cursor: 'pointer' }} onClick={() => onDeleteRelatedProject(cIndex)} />
            <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, marginBottom: 2, color: 'black', fontSize: 16 }}>Dự án:</p>
            <div style={{ marginBottom: 20 }}>
              <Dropdown options={allProjects?.reduce((result, project) => {
                if (!result.find(e => e.name === getCategoryName(project.category))) {
                  result.push({type: 'group', name: getCategoryName(project.category), items: [{ label: project.name, value: project.name }]})
                } else {
                  result.find(e => e.name === getCategoryName(project.category))?.items?.push({ label: project.name, value: project.name })
                }
                return result;
              }, [])} onChange={(e) => { onRelatedProjectChange(e.value, cIndex) }} value={relatedProj?.name} placeholder="Chọn dự án" />
            </div>
          </div>
        )
      })}
      <AddGalleryButton onClick={onAddRelatedProject}>+ Thêm bài</AddGalleryButton>

      {/* action buttons */}
      {isAdmin && location.state?.project?.hidden ? (
        <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-end' }}>
          <ItemLink onClick={onRecover}>Khôi phục</ItemLink>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-end' }}>
          <DeleteButton onClick={onDelete}>Xoá</DeleteButton>
          <ItemLink onClick={onPreview} target="_blank">Xem trước</ItemLink>
          <ItemLink onClick={onSave}>Lưu</ItemLink>
        </div>
      )}

    </Container>
  )
}

const Container = styled.div`
    flex: 1;
    margin: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
`;

const ItemLink = styled.a`
  border-radius: 5px;
  align-self: flex-end;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  text-decoration: none;
  color: white;
  padding: 1rem 2rem;
  margin-top: 50px;

  border: solid  white;
  border-width: 1px;
  text-align: center;
  cursor: pointer;
  background-color: #115b4c;

  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;

  &:hover,
  &:active {
    background-position: 100%;
    color: #115b4c;
  }
`;

const DeleteButton = styled(ItemLink)`
  background-color: #d1001f;
  margin-right: 20px;
`;

const AddGalleryButton = styled.p`
  align-self: stretch;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  color: white;
  padding: 10px;
  margin-top: 10px;
  
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  background-color: #115b4c;

  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;

  /* &:hover,
  &:active {
    background-position: 100%;
    color: #115b4c;
  } */
`;

const AddImageButton = styled.p`
  align-self: flex-start;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  color: white;
  padding: 6px;
  margin-top: 10px;
  
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  background-color: #115b4c;

  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;

  /* &:hover,
  &:active {
    background-position: 100%;
    color: #115b4c;
  } */
`;