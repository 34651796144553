import React, { useEffect, useState } from 'react';
import '../../../App.css';
import styled from 'styled-components';
import useStyles from 'src/hooks/useStyles';
import Dropdown from 'react-dropdown';
import './dropdown.css';
import { isEmpty, validateEmail } from 'src/utils';
import { toast } from 'react-toastify';
import useFirebase from 'src/hooks/useFirebase';
import { useLoading } from 'src/hooks/useLoading';
import { useLocation } from 'react-router-dom';

const ItemLink = styled.p`
  align-self: flex-end;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  text-decoration: none;
  color: black;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  margin-top: 20px;

  border: solid  white;
  border-width: 1px;
  text-align: center;
  cursor: pointer;

  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;

  &:hover,
  &:active {
    background-position: 100%;
    color: #115b4c;
  }
`;

const options = [
  'Hẹn trao đổi trực tiếp',
  'Hẹn tư vấn Online ( Phone, Facebook, Zalo,...)',
  'Phản hồi ý kiến',
  'Tuyển dụng',
  'Báo giá',
  'Khác'
];

const Container = styled.div`
    align-self: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export default function ContactPage() {
  const location = useLocation();
  const styles = useStyles()
  const { showLoading, hideLoading } = useLoading();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [subject, setSubject] = useState();
  const [content, setContent] = useState();
  const { addMail } = useFirebase();

  useEffect(() => {
    localStorage.setItem('@selectedIndexHamburder', 3);
    localStorage.setItem('@selectedIndexBottomBar', 3);
}, [location])

  const onSubmit = async () => {
    if (isEmpty(name)) {
      toast.error('HỌ VÀ TÊN không được để trống');
      return;
    }
    if (isEmpty(email)) {
      toast.error('EMAIL không được để trống');
      return;
    }
    if (!validateEmail(email)) {
      toast.error('EMAIL không hợp lệ');
      return;
    }
    if (isEmpty(phoneNumber)) {
      toast.error('SỐ ĐIỆN THOẠI không được để trống');
      return;
    }
    if (isEmpty(subject)) {
      toast.error('CHỦ ĐỀ không được để trống');
      return;
    }
    if (isEmpty(content)) {
      toast.error('NỘI DUNG không được để trống');
      return;
    }

    showLoading();
    await addMail({ name, email, phoneNumber, subject, content, status: 'unread', timestamp: Date.now() }, 
      () => { toast.error('Đã xảy ra lỗi vui lòng thử lại') },
      () => { toast.success('Chúng tôi sẽ liên hệ với bạn sớm') }
    );
    hideLoading();
    setName('');
    setEmail('');
    setPhoneNumber('');
    setSubject('');
    setContent('');
  }

  return (
    <Container>
      <div style={{ marginTop: 40, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <p style={{ textAlign: 'flex-start', ...styles.titleStyle, marginTop: '1rem', marginBottom: '1rem' }}>
          CÔNG TY TNHH KIẾN TRÚC NỘI THẤT HIDIARCH
        </p>
        
        <p style={{ textAlign: 'center', ...styles.descriptionStyle }}>
          PHONE: <br /> <a style={{ color: 'white', textDecoration: 'none' }} href='tel:0798897288'>0 7 9 8 . 8 9 7 . 2 8 8</a>
        </p>
        <p style={{ textAlign: 'center', ...styles.descriptionStyle }}>
          EMAIL: <br /> <a style={{ color: 'white', textDecoration: 'none' }} href='hidiarch.studio@gmail.com'>hidiarch.studio@gmail.com</a>
        </p>
        <p style={{ textAlign: 'center', ...styles.descriptionStyle }}>
          FACEBOOK: <br /> <a style={{ color: 'white', textDecoration: 'none' }} href='https://www.facebook.com/hidiarchStudio'>https://www.facebook.com/hidiarchStudio</a>
        </p>
        <p style={{ textAlign: 'center', ...styles.descriptionStyle }}>
          OFFICE: <br /> 28 Lý Phục Man, P. Bình Thuận, Quận 7, TP HCM.
        </p>

      </div>

      <p style={{ ...styles.titleStyle, marginTop: 60, marginBottom: '1rem' }}>LIÊN HỆ</p>
      <div style={{ width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'stretch', backgroundColor: 'white', padding: 20, borderRadius: 5 }}>
        {/* <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, textTransform: 'uppercase', marginBottom: 2, color: 'black' }}>Họ và tên:</p> */}
        <input style={{ ...styles.descriptionStyle, height: 40, margin: 0, paddingLeft: 10, borderWidth: 0, borderBottomWidth: 1, borderBottomColor: 'rgba(253, 188, 178, 1)', marginBottom: 20 }} value={name} placeholder={'Họ và tên'} onChange={e => setName(e.target.value)} />

        {/* <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, textTransform: 'uppercase', marginBottom: 2, color: 'black' }}>Email:</p> */}
        <input style={{ ...styles.descriptionStyle, height: 40, margin: 0, paddingLeft: 10, borderWidth: 0, borderBottomWidth: 1, borderBottomColor: 'rgba(253, 188, 178, 1)', marginBottom: 20 }} value={email} placeholder={'Email'} onChange={e => setEmail(e.target.value)} />

        {/* <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, textTransform: 'uppercase', marginBottom: 2, color: 'black' }}>Số điện thoại:</p> */}
        <input style={{ ...styles.descriptionStyle, height: 40, margin: 0, paddingLeft: 10, borderWidth: 0, borderBottomWidth: 1, borderBottomColor: 'rgba(253, 188, 178, 1)', marginBottom: 20 }} value={phoneNumber} placeholder={'Số điện thoại'} onChange={e => setPhoneNumber(e.target.value)} />

        {/* <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, textTransform: 'uppercase', marginBottom: 2, color: 'black' }}>Chủ đề liên hệ:</p> */}
        <Dropdown style={{ width: '100%', height: 100 }} options={options} onChange={(e) => setSubject(e.label)} value={subject} placeholder="Chọn chủ đề" />
        <div style={{width: '100%', height: 1, backgroundColor: 'rgba(253, 188, 178, 1)', marginBottom: 20}}></div>

        {/* <p style={{ textAlign: 'flex-start', ...styles.descriptionStyle, textTransform: 'uppercase', marginBottom: 2, color: 'black' }}>Nội dung bạn đang cần chia sẻ:</p> */}
        <textarea style={{ ...styles.descriptionStyle, boderWidth: 1, border: 'solid 1px', borderColor: 'white', height: 100, margin: 0, padding: 10, borderWidth: 0, borderBottomWidth: 1, borderBottomColor: 'rgba(253, 188, 178, 1)' }} value={content} placeholder={'Nội dung bạn đang cần chia sẻ'} onChange={e => setContent(e.target.value)} />

        <ItemLink onClick={onSubmit} >GỬI</ItemLink>
      </div>
    </Container>
  )
}