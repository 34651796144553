import React, { useEffect, useRef, useState } from 'react';
import '../../../App.css'
import styled from 'styled-components';
import useStyles from 'src/hooks/useStyles';
import { useLocation, useNavigate } from "react-router-dom";

const COLORS = {
  primaryDark: "#115b4c",
  primaryLight: "black",
};

const Container = styled.div`
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  margin: 10px 0px;
  cursor: pointer;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

const ItemLink = styled.p`
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  text-decoration: none;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  margin-top: 1rem;

  border: solid  white;
  border-width: 1px;
  cursor: pointer;

  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;

  &:hover,
  &:active {
    background-position: 100%;
    color: ${COLORS.primaryDark};
  }
`;

export default function ProjectsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const styles = useStyles();

  const [width, setWidth] = useState('auto');
  const ref = useRef(null);

  useEffect(() => {
    localStorage.setItem('@selectedIndexHamburder', 2);
    localStorage.setItem('@selectedIndexBottomBar', 2);
}, [location]);

  useEffect(() => {
    setWidth(ref.current.clientWidth)
  }, [])

  return (
    <Container>
      <img
        ref={ref}
        style={{ width: '100%', height: width * 0.7, borderRadius: 8, objectFit: 'cover', marginTop: 20 }}
        src={require('src/assets/images/100a5c100c8e1376b0d94ebf43ac789a.jpg')}
        alt=''
      />
      <div style={{ marginBottom: 80, alignItems:'flex-start', display: 'flex', flexDirection: 'column' }}>
        <Title style={{ ...styles.titleStyle }} onClick={() => { navigate('/projects/civil-construction') }}>Công Trình Dân Dụng</Title>
        <p style={{ ...styles.subTitleStyle,  margin: 0 }}>Nhà phố, Chung cư ..v.v...... Những giải pháp thiết kế đẹp ấn tượng cho chính không gian sống của bạn.</p>
        <ItemLink onClick={() => { navigate('/projects/civil-construction') }}>Xem thêm</ItemLink>
      </div>

      <img
        style={{ width: '100%', height: '100%', borderRadius: 8, objectFit: 'cover' }}
        src={require('src/assets/images/building_architecture_structure_145399_3840x2160.jpg')}
        alt=''
      />
      <div style={{ marginBottom: 80, alignItems:'flex-start', display: 'flex', flexDirection: 'column' }}>
        <Title style={{ ...styles.titleStyle }} onClick={() => navigate(`${pathname}/public-construction`)}>Công Trình Công Cộng</Title>
        <p style={{ ...styles.subTitleStyle, margin: 0 }}>Coffee, Shop, Store, Hotel, Restaurant, Resort..v.v..... Và còn rất nhiều thể loại công trình đang chờ bạn khám phá</p>
        <ItemLink onClick={() => navigate(`${pathname}/public-construction`)}>Xem thêm</ItemLink>
      </div>

      <img
        style={{ width: '100%', height: '100%', borderRadius: 8, objectFit: 'cover' }}
        src={require('src/assets/images/One_Design_002-Robert-Randall-Advertising-Photographer-Commercial-Photographer-CGI-Portrait-and-Lifestyle-Photography.jpg')}
        alt=''
      />
      <div style={{ marginBottom: 80, alignItems:'flex-start', display: 'flex', flexDirection: 'column' }}>
        <Title style={{ ...styles.titleStyle }} onClick={() => navigate(`${pathname}/actual`)}>Thi Công Thực Tế</Title>
        <p style={{ ...styles.subTitleStyle, margin: 0 }}>Trải nghiệm những hình ảnh thực tế được hoàn thiện tối ưu nhất bởi chính đội ngủ của Hidiarch</p>
        <ItemLink onClick={() => navigate(`${pathname}/actual`)}>Xem thêm</ItemLink>
      </div>

    </Container>
  )
}