import React, { useEffect, useRef, useState } from "react";
import BottomRightImage from "./images/homepage_hero_top_right.jpg";
import MobileRightImage from "./images/homepage_hero_top_right_mobile.jpg";
import TopRightImage from "./images/homepage_hero_bottom.jpg";
import MobileBottomImage from "./images/homepage_hero_bottom_mobile.jpg";
import LeftImage from "./images/homepage_hero_left.jpg";
import ScrollAnimation from "../ScrollAnimation";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import useMobileOrTablet from "src/hooks/useMediaQuery";

const HeroBanner = (props) => {
  const isMobile = useMobileOrTablet();
  const { height } = useWindowDimensions();
  const subTitleRef = useRef();
  const [minHeight, setminHeight] = useState();
  const [lineWidth, setlineWidth] = useState(0);
  const [lineVisible, setlineVisible] = useState(false);
  useEffect(() => {
    if (!lineVisible) {
      setlineWidth(subTitleRef.current?.offsetWidth);
      setlineVisible(true)
    }

  }, [subTitleRef])

  useEffect(() => {
    if (!minHeight) {
      setminHeight(height - 56)
    }
  }, [height])

  if (isMobile) {
    return (
      <div className="relative flex bg-heroBg w-[100vw] gap-[20px] items-center" style={{ minHeight: minHeight }}>
        <div className="flex flex-col w-[100vw] self-stretch justify-between">
          <div className="flex flex-col gap-[5%] h-full justify-between f-auto">
            <div className="flex">
              <div className="w-[40%]" />
              <img
                alt=""
                src={LeftImage}
                className="object-cover w-[32vw] aspect-[3/4]"
                style={{ maxHeight: .25 * minHeight, maxWidth: .25 * minHeight * 3 / 4 }}
              />
            </div>
            <div className="flex md:flex-col w-[100%] items-start my-[20px]">
              <div className="flex flex-col gap-[10px] w-full p-[20px] pt-0">
                <h1 className="text-primary text-[40px] leading-[1] md:text-left uppercase animation-delay-300 animation-fadeIn">
                  HIDIARCH <br />
                  STUDIO
                </h1>
                <ScrollAnimation animateIn=" md:mt-0 animate-delayBounceInRight">
                  <div className="h-[1px] bg-primary" style={{ width: lineWidth }} />
                </ScrollAnimation>
                <h3 className="text-primary text-left text-[14px] animation-delay-500 animation-fadeIn font-medium">
                  <span ref={subTitleRef}>Kiến Trúc & Nội Thất</span><br /> Your Dreams, Our Passion
                </h3>
                <p className="md:w-[30vw] w-full text-justify animation-delay-400 animation-fadeIn" style={{ fontSize: 12, color: 'black' }}>
                  Khởi nguồn cho ý tưởng là Bạn, tạo hình về giá trị là Chúng tôi. Hãy để Hidiarch mang đến giá trị niềm tin cho bạn về không gian sống mơ ước.
                </p>
              </div>
              <img
                alt=""
                className="top-0 w-[40%] aspect-[1201/1500] object-cover"
                src={MobileRightImage}
              />
            </div>
            <img
              alt=""
              className="md:right-0 object-cover bottom-0 object-cover w-[70vw] aspect-[1440/636]"
              src={MobileBottomImage}
            />
          </div>
        </div>
      </div>
    )
  }
  // if (isMobile) {
  //   return (
  //     <div className="relative flex bg-heroBg w-[100vw] gap-[20px] items-center" style={{ minHeight: minHeight }}>
  //       <div className="flex flex-col w-[70vw] self-stretch justify-between">
  //         <div className="flex flex-col gap-[5%] h-full justify-between f-auto">
  //           <div className="flex">
  //             <div className="w-[40%]" />
  //             <img
  //               alt=""
  //               src={LeftImage}
  //               className="object-cover w-[32vw] aspect-[3/4]"
  //               style={{ maxHeight: .25 * minHeight, maxWidth: .25 * minHeight * 3 / 4 }}
  //             />
  //           </div>
  //           <div className="flex md:flex-col w-[90%] items-center my-[20px]">
  //             <div className="flex flex-col gap-[10px] w-full p-[20px]">
  //               <h1 className="text-primary text-[40px] leading-[1] md:text-left uppercase animation-delay-300 animation-fadeIn">
  //                 HIDIARCH <br />
  //                 STUDIO
  //               </h1>
  //               <ScrollAnimation animateIn=" md:mt-0 animate-delayBounceInRight">
  //                 <div className="h-[1px] bg-primary" style={{ width: lineWidth }} />
  //               </ScrollAnimation>
  //               <h3 className="text-primary text-left text-[14px] animation-delay-500 animation-fadeIn font-medium">
  //                 <span ref={subTitleRef}>Kiến Trúc & Nội Thất</span><br /> Your Dreams, Our Passion
  //               </h3>
  //               <p className="md:w-[30vw] w-full text-justify animation-delay-400 animation-fadeIn" style={{ fontSize: 12, color: 'black' }}>
  //                 Khởi nguồn cho ý tưởng là Bạn, tạo hình về giá trị là Chúng tôi. Hãy để Hidiarch mang đến giá trị niềm tin cho bạn về không gian sống mơ ước.
  //               </p>
  //             </div>
  //           </div>
  //           <img
  //             alt=""
  //             className="md:right-0 object-cover bottom-0 object-cover w-[70vw] aspect-[1440/636]"
  //             src={MobileBottomImage}
  //           />
  //         </div>
  //       </div>
  //       <div className="">
  //         <img
  //           alt=""
  //           className="top-0 w-[40vw] aspect-[1201/1500] object-cover"
  //           src={MobileRightImage}
  //         />
  //       </div>
  //     </div>
  //   )
  // }
  return (
    <div className="relative flex bg-heroBg" style={{ minHeight: minHeight }}>
      <div className="flex flex-col md:flex-row h:[100vw] md:h-[80%] md:w-[60vw] self-center items-center">
        <div className="flex flex-col md:flex-row gap-[5%] justify-between">
          <img
            alt=""
            src={LeftImage}
            className="object-cover  md:w-[18vw] aspect-[3/4]"
          />
          <div className="flex md:flex-col w-full items-center">
            <div className="flex md:flex-col gap-[10px] w-[80%]">
              <h1 className="text-primary text-[6.5625rem] leading-[1] md:mt-[-10px] md:text-left uppercase animation-delay-300 animation-fadeIn">
                HIDIARCH <br />
                STUDIO
              </h1>
              <ScrollAnimation animateIn="mt-[25px] md:mt-0 animate-delayBounceInRight">
                <div className="h-[1px] bg-primary" style={{ width: lineWidth }} />
              </ScrollAnimation>

              <h3 className="text-primary md:mt-[10px] text-center md:text-left animation-delay-500 animation-fadeIn font-medium" style={{ fontSize: 18, color: 'black' }}>
                <span ref={subTitleRef}>Kiến Trúc & Nội Thất</span> <span className="px-2">|</span> Your Dreams, Our Passion
              </h3>
              <p className="md:w-[30vw] w-full text-center md:text-left text-justify animation-delay-400 animation-fadeIn" style={{ fontSize: 14, color: 'black' }}>
                Khởi nguồn cho ý tưởng là Bạn, tạo hình về giá trị là Chúng tôi. Hãy để Hidiarch mang đến giá trị niềm tin cho bạn về không gian sống mơ ước.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-[40vw]">
        <img
          alt=""
          className="absolute md:right-0 object-cover md:top-0 object-cover md:w-[18vw] aspect-[1500/2000]"
          src={TopRightImage}
          style={{ maxHeight: .55 * minHeight }}
        />
        <img
          alt=""
          className="absolute top-0 md:top-auto md:w-[25vw] md:bottom-0 left-0 md:left-unset aspect-[2000/1500] object-cover"
          src={BottomRightImage}
          style={{ maxHeight: .35 * minHeight }}
        />
      </div>
    </div>
  );
};

export default HeroBanner;
