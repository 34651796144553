import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Carousel1 from "./images/HomePage-Testimonials_1.jpg";
import Carousel2 from "./images/HomePage-Testimonials_2.jpg";
import Carousel3 from "./images/HomePage-Testimonials_3.jpg";
import Carousel4 from "./images/HomePage-Testimonials_4.jpg";
import Carousel5 from "./images/HomePage-Testimonials_5.jpg";
import Carousel6 from "./images/HomePage-Testimonials_6.jpg";
import Carousel7 from "./images/HomePage-Testimonials_7.jpg";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import useStyles from "src/hooks/useStyles";
import useMobileOrTablet from "src/hooks/useMediaQuery";
import "./styles.css";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const CAROUSELS = [
  {
    id: 1,
    image: Carousel1,
    description:
      "“Không gì tuyệt vời hơn khi KTS của Hidiarch giải đáp và đồng hành cùng tổ ấm đầu tiên của 2 vợ chồng từ bản vẽ đến hoàn thiện một cách thật yên tâm nhất.”",
    name: "LÝ HUỲNH MINH TRUNG",
    address: "NHÀ PHỐ NHÀ BÈ – QUẬN 7",
  },
  {
    id: 2,
    image: Carousel2,
    description:
      "“Ấp ủ khởi nghiệm nên việc lựa chọn một đơn vị thiết kế đúng ý, thi công đúng chuẩn là ưu tiên hàng đầu. Thật may mắn vì Hidiarch đã cùng tôi vẽ lên ước mơ và hiện thực hóa nó.. mãi iu Hidiarch.”",
    name: "TRẦN THỊ THANH",
  },
  {
    id: 3,
    image: Carousel3,
    description:
      "“Hidiarch đã thể hiện được kinh nghiệm cùng bãn lĩnh của đội ngũ KTS đã mang đến một thiết kế thật ấn tượng vừa đáp ứng công năng vừa hài hòa Phong Thủy.”",
    name: "NGUYỄN HOÀNG ANH DUY",
  },
  {
    id: 4,
    image: Carousel4,
    description:
      "“Mong muốn hình thành nên một căn hộ vừa đơn giản, vừa dễ thương, vừa phù hợp với chi phí đề ra đã được đội ngủ Hidiarch lắng nghe và nỗ lực hết mình.“",
    name: "TRẦN THỊ TRÚC PHƯƠNG",
  },
  {
    id: 5,
    image: Carousel5,
    description:
      "“Cảm ơn Hidiarch vì đã thay đổi biến hóa ngôi nhà từ cũ kỉ trở thành một không gian sống ngoài sức tưởng tượng của bản thân.. không gì hạnh phúc hơn.”",
    name: "TRẦN THỊ ÁI THỦY",
  },
  {
    id: 6,
    image: Carousel6,
    description:
      "“Thiết kế đúng nhu cầu sở thích của tôi, thi công chuyên nghiệp. Đánh giá đơn giản là tôi hài lòng ở các bạn.”",
    name: "TRẦN THỊ TRANG",
  },
  {
    id: 7,
    image: Carousel7,
    description:
      "“Hidiarch giúp tôi có những giải pháp tối ưu nhất với không gian nhỏ của ngôi nhà cùng những trải nghiệm thực tế thật sự hài lòng từ công năng đến thẩm mỹ.”",
    name: "NGUYỄN ANH TÂM",
  },
];

const FontAwesomeIconAnimated = styled(FontAwesomeIcon)`
  transition: all 0.4s;
  &:hover,
  &:active {
    transform: scale(1.1);
  }
`;

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <>
      <div
        className="absolute cursor-pointer top-1/2 -translate-y-1/2 right-0 w-[43px] h-[43px] rounded-full bg-[#00000080] hover:bg-[#000c] flex justify-center items-center text-white text-[20px] md:right-[-50px]"
        onClick={() => next()}
      >
        <FontAwesomeIconAnimated icon={faChevronRight} />
      </div>
      <div
        className="fa-solid fa-chevron-left absolute cursor-pointer top-1/2 -translate-y-1/2 left-0 w-[43px] h-[43px] rounded-full bg-[#00000080] hover:bg-[#000c] flex justify-center items-center text-white text-[20px] md:left-[-50px]"
        onClick={() => previous()}
      >
        <FontAwesomeIconAnimated icon={faChevronLeft} />
      </div>
    </>
  );
};

const CarouselComponent = (props) => {
  const isMobileOrTablet = useMobileOrTablet();
  const styles = useStyles();
  const { font2, font3 } = props;
  return (
    <Carousel
      additionalTransfrom={0}
      autoPlay
      autoPlaySpeed={isMobileOrTablet ? 3000 : 5000}
      centerMode={false}
      className="container mx-auto"
      containerClass="w-[100vw] md:w-[50vw]"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={true}
      arrows={false}
      customButtonGroup={<ButtonGroup />}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 768,
          },
          items: 3,
          partialVisibilityGutter: 40,
        },
        tablet: {
          breakpoint: {
            max: 768,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      slidesToSlide={isMobileOrTablet ? 1 : 3}
      swipeable
    >
      {CAROUSELS.map((carousel) => (
        <CarouselItem
          key={carousel.id}
          {...carousel}
          styles={styles}
          {...{ font2, font3 }}
        />
      ))}
    </Carousel>
  );
};

const CarouselItem = ({
  id,
  image,
  description,
  name,
  address,
  styles,
  font2,
  font3,
}) => {
  return (
    <div className="relative h-auto bg-heroBg m-[5%] padding-x-box">
      <div className="container h-full w-[50vw] md:w-[100%] m-auto flex flex-col md:justify-evenly items-center gap-[20px]">
        <img src={image} className="w-full object-cover" alt="" />
        <div>
          <strong
            className="text-center md:text-left w-full md:w-[360px] sub-text-size"
            style={{ fontSize: parseInt(font2) }}
          >
            {name}
          </strong>
          <p
            className="text-center w-full mx-auto md:text-left text-primary sub-text-size text-justify"
            style={{ fontSize: parseInt(font3) }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CarouselComponent;
