import React, { useEffect, useRef, useState } from 'react';
import '../../../../App.css'
import styled from 'styled-components';
import useStyles from 'src/hooks/useStyles';
import { scrollToTopWithoutAnimation } from 'src/utils';
import { useLocation, useNavigate } from 'react-router-dom';

const COLORS = {
  primaryDark: "#115b4c",
  primaryLight: "black",
};

const Container = styled.div`
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const ItemLink = styled.p`
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  text-decoration: none;
  color: white;
  padding: 0.5rem 2rem;
  margin: 10px 0px;
  border-radius: 5px;

  border: solid  white;
  border-width: 1px;
  cursor: pointer;

  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;

  &:hover,
  &:active {
    background-position: 100%;
    color: ${COLORS.primaryDark};
  }
`;

const Title = styled.p`
  margin: 10px 0px;
  cursor: pointer;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

export default function ProjectsCategoryPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // const kind = useMemo(() => {
  //   const { pathname } = location;

  // }, [])

  const styles = useStyles();

  const [width, setWidth] = useState('auto');
  const ref = useRef(null);

  useEffect(() => {
    setWidth(ref.current.clientWidth);
    scrollToTopWithoutAnimation();
  }, [])

  return (
    <Container>
      <img
        ref={ref}
        style={{ width: '100%', height: '100%', borderRadius: 8, objectFit: 'cover', marginTop: 20 }}
        src={require('src/assets/images/1121212121.jpg')}
        alt=''
      />
      <p style={{ ...styles.subTitleStyle, textAlign: 'center' }}>Nhà phố, Chung cư ..v.v...... Những giải pháp thiết kế đẹp ấn tượng cho chính không gian sống của bạn.</p>

      <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', width: '100%', marginTop: 20, marginBottom: 60 }}>
        <div style={{ margin: 10, flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img
            ref={ref}
            style={{ borderRadius: 8, width: '100%', height: width, objectFit: 'cover' }}
            src={require('src/assets/images/Organic-Meets-Futuristic-Design-House-in-The-Landscape-feature-1400x933.jpg')}
            alt='' />
          <Title style={styles.subTitleStyle} onClick={() => navigate(`${pathname}/townhouse`)}>THIẾT KẾ NHÀ PHỐ</Title>
          <ItemLink onContextMenu={(e) => e.preventDefault()} onClick={() => navigate(`${pathname}/townhouse`)}>Xem thêm</ItemLink>
        </div>

        <div style={{ margin: 10, flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img
            style={{ borderRadius: 8, width: '100%', height: width, objectFit: 'cover' }}
            src={require('src/assets/images/c786182b37f127b6c74cfed629589781.jpg')}
            alt='' />
          <Title style={styles.subTitleStyle} onClick={() => navigate(`${pathname}/apartment`)}>THIẾT KẾ CHUNG CƯ</Title>
          <ItemLink onClick={() => navigate(`${pathname}/apartment`)}>Xem thêm</ItemLink>
        </div>
      </div>

    </Container>
  )
}