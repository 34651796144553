import React from "react";
import useMobileOrTablet from "src/hooks/useMediaQuery";
import useStyles from "src/hooks/useStyles";
import { openInNewTab } from "src/utils";
import styled from "styled-components";

const COLORS = {
  primaryDark: "#115b4c",
  primaryLight: "black",
};

const ItemLink = styled.p`
  border-radius: 5px;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  text-decoration: none;
  color: white;
  padding: 0.5rem 2rem;

  border: solid white;
  border-width: 1px;
  cursor: pointer;

  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;

  &:hover,
  &:active {
    background-position: 100%;
    color: ${COLORS.primaryDark};
  }
`;

export default function CardInfo({
  name,
  role,
  slogan,
  profileImageUrl,
  profilePage,
}) {
  const styles = useStyles();
  const isMobileOrTablet = useMobileOrTablet();

  return (
    <div style={{ marginTop: 40 }}>
      <p
        style={{
          ...styles.titleStyle,
          textAlign: "center",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        {name}
      </p>

      <div
        style={{
          display: "flex",
          flexDirection: isMobileOrTablet ? "column" : "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <img
          src={profileImageUrl}
          alt=""
          style={{ width: isMobileOrTablet ? "100%" : "50%", borderRadius: 8 }}
        />
        <div
          style={{
            width: isMobileOrTablet ? "100%" : "50%",
            marginLeft: isMobileOrTablet ? 0 : 50,
          }}
        >
          <p
            style={{
              ...styles.subTitleStyle,
              marginTop: "1em",
              marginBottom: "1em",
            }}
          >
            {role}
          </p>
          <p
            style={{
              ...styles.titleStyle,
              marginTop: "1em",
              marginBottom: "1em",
            }}
          >
            {slogan}
          </p>
          <ItemLink onClick={() => openInNewTab(profilePage)} to="">
            Xem thêm
          </ItemLink>
        </div>
      </div>
    </div>
  );
}
