import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import Dropdown from 'react-dropdown';
import { useNavigate } from 'react-router-dom';
import { useLoading } from 'src/hooks/useLoading';
import useFirebase from 'src/hooks/useFirebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Container = styled.div`
    flex: 1;
    padding: 10px;
    position: relative;
`;

const AddButton = styled(FontAwesomeIcon)`
    transition: all 0.4s;
    position: absolute;
    background-color: #115b4c;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 10px;
    right: 50px;
    bottom: 50px;
    border-radius: 100px;
    &:hover,
    &:active {
        transform: scale(1.1);
    };
`;

const options = [
  { label: 'Thiết kế nhà phố', value: 'townhouse' },
  { label: 'Thiết kế chung cư', value: 'apartment' },
  { label: 'Công trình công cộng', value: 'public-construction' },
  { label: 'Thi công thực tế', value: 'actual' },
];

export default function ManageProjectsPage() {
  const navigate = useNavigate();
  const [category, setCategory] = useState();
  const [projects, setProjects] = useState([]);
  const loading = useLoading();
  const { getProjects, updateProjectsPosition } = useFirebase();

  // Function to update list on drop
  const handleDrop = async (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...projects];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setProjects(updatedList);
    // Request API
    loading.showLoading();
    await updateProjectsPosition(updatedList);
    loading.hideLoading();
  };

  useLayoutEffect(() => {
    setCategory(localStorage.getItem('@category'));
  }, [])

  useEffect(() => {
    async function fetchData() {
      localStorage.setItem('@category', category);
      loading.showLoading();
      const isAdmin = localStorage.getItem('@username') === 'hidiarch.studio@gmail.com';
      const data = await getProjects(category)
      setProjects(isAdmin ? data : data.filter(el => el.hidden !== true ));
      loading.hideLoading()
    }
    category && fetchData()
  }, [category])

  return (
    <Container>
      <Dropdown style={{ width: '100%', height: 100 }} options={options} onChange={(e) => setCategory(e.value)} value={category} placeholder="Chọn chủ đề" />
      <div style={{ overflow: 'scroll', height: window.innerHeight - 60, marginRight: - 50 }}>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-container">
            {(provided) => (
              <div
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {projects.length > 0 && projects.map((project, index) => (
                  <Draggable key={project.name} draggableId={project.name} index={index}>
                    {(provided) => (
                      <div
                        className="item-container"
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      >
                        <div
                          key={project.name}
                          onClick={() => { navigate('/admin/projects-detail', { state: { project } }) }}
                          style={{ boxShadow: '2px 2px 10px 2px #00000020', padding: 20, backgroundColor: 'white', margin: 0, marginTop: 10, marginRight: 50, borderRadius: 5, position: 'relative', cursor: 'pointer', overflow: 'hidden' }}>
                          <p style={{ color: 'black', fontSize: 16, margin: 0 }}>{project.name}</p>
                          {(project.description ?? []).map(d => <p style={{ color: 'grey', margin: 0, fontSize: 14 }}>{d}</p>)}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* {projects.length > 0 && projects.map(project => {
            return (
              <div
                key={project.name}
                onClick={() => { navigate('/admin/projects-detail', { state: { project } }) }}
                style={{ boxShadow: '2px 2px 10px 2px #00000020', padding: 20, backgroundColor: 'white', margin: 0, marginTop: 10, borderRadius: 5, position: 'relative', cursor: 'pointer', overflow: 'hidden' }}>
                <p style={{ color: 'black', fontSize: 16, margin: 0 }}>{project.name}</p>
                {(project.description ?? []).map(d => <p style={{ color: 'grey', margin: 0, fontSize: 14 }}>{d}</p>)}
              </div>
            )
          })} */}
      </div>
      <AddButton icon={faPlus} size="sm" color='white' onClick={() => { navigate('/admin/projects-detail') }} />
    </Container>
  )
}