import React, { useEffect } from 'react';
import '../../../App.css'
import styled from 'styled-components';
import Image from 'src/assets/images/skyscraper_building_architecture_112550_3840x2160.jpg'
import useMobileOrTablet from 'src/hooks/useMediaQuery';
import useStyles from 'src/hooks/useStyles';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
    align-self: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export default function HomePage() {
    const location = useLocation();
    const isMobileOrTablet = useMobileOrTablet();
    const styles = useStyles()

    useEffect(() => {
        localStorage.setItem('@selectedIndexHamburder', 0);
        localStorage.setItem('@selectedIndexBottomBar', 0);
    }, [location])
    
    return (
        <Container>
            <img src={Image} style={{width: '100%', borderRadius: 8, marginRight: 10, marginTop: 10}} alt='' />
            
            <p style={{marginTop: 30, marginBottom: "1rem", ...styles.titleStyle}}>TƯ VẤN - THIẾT KẾ - THI CÔNG</p>
            <p style={{textAlign: 'center', ...styles.descriptionStyle}}>"Sẽ không bao giờ có kiến trúc sư vĩ đại hay công trình vĩ đại nếu không có chủ đầu tư vĩ đại."<br />
            - Emilio Ambasz -</p>
            <p style={{textAlign: 'center', ...styles.descriptionStyle}}>"Không gian là nguồn sống và hơi thở của nghệ thuật." <br />
            - Frank Lloyd Wright -</p>

            <div style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'row', width: '100%', marginTop: 20}}>
                <div style={{margin: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img
                        style={{width: '100%', height: isMobileOrTablet ? '80%' : '90%', borderRadius: 8}} 
                        src={require('src/assets/images/31b73e432fc3c73d442d26eac6a1201f.jpg')}
                        alt='' />
                        <p style={{...styles.subTitleStyle, textAlign: 'center', marginTop: "1rem", marginBottom: "1rem"}}>Admire<br />The Architecture!</p>
                </div>

                <div style={{margin: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img
                        style={{width: '100%',height: isMobileOrTablet ? '80%' : '90%', borderRadius: 8}} 
                        src={require('src/assets/images/489e8017c00d1b07f3e05965dd71f959.jpg')}
                        alt='' />
                    <p style={{...styles.subTitleStyle, textAlign: 'center', marginTop: "1rem", marginBottom: "1rem"}}>Enjoy <br /> The Interior!</p>
                </div>
            </div>
        </Container>
    )
}