import React, { useRef, useState } from "react";
import cx from "clsx";
import Button from "src/components/Button";
import useClickOutside from "src/hooks/useClickOutSide";
import CloseIcon from "src/assets/images/close.svg";
import { useNavigate } from "react-router-dom";

const Banner = (props) => {
  const ref = useRef();
  const navigate = useNavigate();

  const [showBanner, setShowBanner] = useState(true);
  const [playHideAnimation, setPlayHideAnimation] = useState(false);

  const handleCloseModal = () => {
    setPlayHideAnimation(true)
    setTimeout(() => {
      setShowBanner(false)
    }, 400)
  }
  useClickOutside(ref, () => {
    handleCloseModal(false);
  });
  if (!showBanner) return null;
  return (
    <div className="fixed top-0 z-[99999] w-full bg-black bg-opacity-70 h-full">
      <div
        ref={ref}
        className={cx("relative bg-white h-[300px] md:h-[390px] flex flex-col justify-end", {
          "animate-bounceOutTop": playHideAnimation,
          "animate-bounceInTop": showBanner,
        })}
      >
        <div
          className="absolute right-2 md:right-[5%] top-2 md:top-[5%] p-2 rounded-full cursor-pointer transition-all hover:scale-[120%]"
          onClick={handleCloseModal}
        >
          <img src={CloseIcon} alt="" />
        </div>
        <div
          className="flex flex-col gap-[10px] md:gap-[20px] p-[40px] md:p-[80px] relative"
          style={{
            background: `url(${require("./images/Popup-BG.jpg")})`,
            objectFit: "cover",
          }}
        >
          <h1 className="text-primary title-2" style={{fontSize: 24}}>
            TƯ VẤN MIỄN PHÍ
          </h1>
          <p className="text-primary mb-[10px] md:mb-[5px]" style={{fontSize: 14}}>
            Trực tiếp tại công trình hoặc gặp gỡ tại showroom
          </p>
          <Button
            onClick={() => {
              navigate("/contact");
            }}
          >
            Đặt lịch hẹn
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
