import React, { Fragment, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import cx from "clsx";
import MenuIcon from "src/assets/images/menu.svg";
import CloseIcon from "src/assets/images/close.svg";
import ExpandIcon from "src/assets/images/expandIcon.svg";
import Logo from "../../../../assets/images/LOGO-DOC-1.jpg";
import { useNavigate } from "react-router-dom";

const NavbarItems = [
  {
    title: "Trang chủ",
    key: "home",
    url: "/",
    children: [],
  },
  {
    title: "Về chúng tôi",
    key: "aboutUs",
    url: "/",
    children: [],
  },
  {
    title: "Dịch Vụ",
    key: "service",
    url: "/",
    children: [
      {
        title: "Kiến trúc xây dựng",
        key: "construction-architecture",
        url: "/",
      },
      {
        title: "Thiết kế nội thất",
        key: "interior-design",
        url: "/",
      },
      {
        title: "Thiết kế cải tạo",
        key: "renovationDesign",
        url: "/",
      },
      {
        title: "Thiết kế khác",
        key: "interior-construction",
        url: "/",
      },
    ],
  },
  {
    title: "Dự Án",
    key: "Project",
    url: "/",
    children: [],
  },
  {
    title: "Hỗ trợ",
    key: "about",
    url: "/",
    children: [
      {
        title: "Hotline CSKH",
        key: "CSKH",
        url: "/",
      },
      {
        title: "Đăng ký tư vấn",
        key: "signupForConsultation",
        url: "/",
      },
    ],
  },
];

let interval;

const Navbar = (props) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [showActionFadeout, setShowActionFadeout] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const navigate = useNavigate();
  const [percent, setPercent] = useState(0);
  const [activeTabs, setActiveTabs] = useState([]);

  useEffect(() => {
    if (percent >= 99) {
      clearInterval(interval);
      setActiveTab(null);
    }
  }, [percent]);

  const handleNavbarItemClick = (tab) => {
    setPercent(0);
    setActiveTab(tab);
    clearInterval(interval);
    interval = setInterval(function () {
      setPercent((percent) => percent + 1);
    }, 1);
    if (activeTabs.includes(tab)) {
      setActiveTabs(activeTabs.filter((activeTab) => activeTab !== tab));
    } else {
      setActiveTabs((activeTabs) => [...activeTabs, tab]);
    }
  };

  const closeMenu = () => {
    setShowActionFadeout(true);
    setTimeout(() => {
      setIsOpenMenu(false)
      setShowActionFadeout(false)
      setActiveTabs([])
    }, 400)
  }

  const handleHomeClick = () => {
    navigate("/v2");
  };

  const renderNavbarItems = () => {
    return NavbarItems.map((item, index) => {
      const lastItem = index === NavbarItems.length - 1;
      return (
        <div className="relative group py-8 z-10" key={index}>
          <div className="group cursor-pointer">
            <div
              key={item.key}
              className={cx(
                "text-[14px] text-[#757575] group-hover:text-black"
              )}
              onClick={() => setActiveTab(item.key)}
            >
              {item.title}
            </div>
            <div className="opacity-0 border-t-[1px] invisible transition-all duration-300 ease-in group-hover:opacity-100 group-hover:visible"></div>
          </div>
          {!!item.children.length && (
            <div
              className={cx(
                "hidden absolute z-50 group-hover:block w-[250px] cursor-pointer bg-white shadow-md translate-y-[22px] py-4 px-2",
                {
                  "translate-x-[calc(-100%+50px)]": lastItem,
                }
              )}
            >
              {item.children.map((item) => {
                return (
                  <div
                    key={item.key}
                    className="py-3 text-[14px] text-[#757575] px-5 hover:animate-pulsate hover:animation-fill hover:text-black transition-all"
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  };

  const renderAvatar = () => {
    return (
      <div className="flex items-center gap-2 uppercase">
        <img src={Logo} onClick={handleHomeClick} className="w-[30px] h-[30px] border rounded-[15px] cursor-pointer" alt="" />
        <h1 className="text-black text-[12px] font-bold md:text-left">
          Hidiarch Studio
        </h1>
      </div>
    );
  };

  const renderNavbarItemsMobile = (item) => {
    const { children } = item;
    return children.map((item) => {
      return (
        <div
          key={item.key}
          className="py-4 text-sm text-primary hover:text-black"
        >
          {item.title}
        </div>
      );
    });
  };

  const renderNavbarMobile = () => {
    return NavbarItems.map((item) => {
      const _isActive = activeTabs.includes(item.key);
      const sizeOfChildren = item.children.length;
      const heightOfMenuItems = sizeOfChildren * 52;
      const currentHeight = activeTabs.includes(item.key)
        ? (percent / 100) * heightOfMenuItems
        : heightOfMenuItems - (percent / 100) * heightOfMenuItems;
      return (
        <Fragment key={item.key}>
          <div
            className={cx(
              "flex justify-between text-sm cursor-pointer py-[12px] px-[20px]"
            )}
            onClick={() => handleNavbarItemClick(item.key)}
          >
            <p className="text-sm text-black">{item.title}</p>
            {!!sizeOfChildren && (
              <img
                alt=""
                src={ExpandIcon}
                className={cx("transition-all duration-300 w-[16px] h-[16px]", {
                  "rotate-180": _isActive,
                })}
              />
            )}
          </div>
          {!!sizeOfChildren && (
            <div
              style={{
                height:
                  activeTab === item.key
                    ? currentHeight
                    : _isActive
                      ? heightOfMenuItems
                      : 0,
              }}
              className={cx("px-[40px] transition-transform overflow-hidden", {
              })}
            >
              {renderNavbarItemsMobile(item)}
            </div>
          )}
        </Fragment>
      );
    });
  };

  if (isMobile) {
    return (
      <div className="relative border-b-2 border-[#141414] py-[8px] px-[16px]">
        <div className="container mx-auto flex justify-between items-center ">
          {/** Logo */}
          <div>{renderAvatar()}</div>
          <div className="flex flex-row">
            <div className="text-[12px]">
              <button className="border rounded-[5px] mr-[20px] uppercase bg-black h-max my-auto text-white py-1 px-4 uppercase transition-colors duration-500 hover:bg-white hover:text-black">
                HOTLINE
              </button>
            </div>
            <img
              alt=""
              height={22}
              width={22}
              src={MenuIcon}
              onClick={() => setIsOpenMenu(!isOpenMenu)}
            />
          </div>
        </div>
        {isOpenMenu && (
          <div
            className={cx(
              "animation-fill shadow-2xl border-r-[1px] z-[9999999999999] absolute h-screen top-0 left-0 border-1 bg-white w-[320px] z-10 border-b-2 border-[#141414]",
              {
                "animate-slideInLeft": isOpenMenu,
                "animate-slideOutLeft ": showActionFadeout,
              }
            )}
          >
            <div className="p-[15px] mb-2 flex justify-between">
              {renderAvatar()}
              <img
                height={20}
                width={20}
                src={CloseIcon}
                alt="close icon"
                onClick={closeMenu}
              />
            </div>
            {renderNavbarMobile()}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="h-[56px] flex justify-between items-center px-[10px] border-b-[3px] border-black bg-[#ffffff]">
      <div className="flex items-center gap-2 uppercase w-3/12 ">
        <img src={Logo} onClick={handleHomeClick} className="w-[30px] h-[30px] border rounded-[15px] cursor-pointer" alt="" />
        <h1 className="text-black text-[12px] font-bold md:text-left">
          Hidiarch Studio
        </h1>
      </div>
      <div className="hidden md:flex flex-wrap justify-end md:justify-center lg:justify-end gap-[40px] md:w-full lg:w-9/12 mr-[50px]">
        {renderNavbarItems()}
      </div>
      <div className="text-[12px]">
        <button className="border rounded-[5px] uppercase bg-black h-max my-auto text-white py-1 px-4 uppercase transition-colors duration-500 hover:bg-white hover:text-black">
          HOTLINE
        </button>
      </div>
    </div>
  );
};

export default Navbar;
